<template>
    <div class="body-notifications__item item-notifications">
        <div class="item-notifications__name">
          {{ notification.text }}
        </div>
        <div class="item-notifications__bottom">
            <div class="item-notifications__date">
                {{ new Intl.DateTimeFormat('ru-RU').format(Date.parse(notification.created_at)) }}
            </div>
            <a href="#" class="item-notifications__link" @click="this.deleteNotification(notification.id)">
                Прочитать
<!--                <img src="@/assets/icons/red_arrow_right.svg" alt="Отреагировать">-->
            </a>
        </div>
    </div>
</template>

<script>
import {deleteNotifications} from '@/api/get';
import store from "@/store";

export default {
  props: ["notification"],

  methods: {
    async deleteNotification(IdNotification) {
      await deleteNotifications(localStorage.getItem('token'),[IdNotification])
      if (this.$store.state.notifications.length < 1) {
        this.$store.state.enableNotification = false;
      }
    },
  },

  mounted() {

  }

}
</script>

<style>

</style>