import axios from "axios";
import store from "@/store";
// import {response} from "express";
// import router from '../router'

export async function getHeader(token) {
  return await axios
    .get(`${process.env.VUE_APP_URL}header`, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      store.state.headerData = response.data;
      // console.log(response)
    })
    .catch((error) => {
      // console.log(error)
      if (error.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getUserDocuments(token) {
  return await axios
    .get(`${process.env.VUE_APP_URL}documents/list`, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response)
      store.state.userDocuments = response.data.documents;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getUserData(token) {
  return await axios
    .get(`${process.env.VUE_APP_URL}users/profile`, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response)
      store.state.userData = response.data.user_data;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getLastDeals(token, payload) {
  let options = {
    limit: payload.limit,
    filters: payload.filters,
    search: payload.search,
  };
  return await axios
    .post(`${process.env.VUE_APP_URL}deals`, options, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response)
      store.state.lastDeals = response.data.deals;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function deleteNotifications(token, ids){
  let _ids = {
    ids: ids
  }

  return await axios
      .delete(`${process.env.VUE_APP_URL}notifications`, {
        headers: { "Authorization-Token": token }, data: _ids,
      }).then((response)=>{
    console.log(response);
    if (response.status == 200){
      ids.forEach((id)=>{
        var index = store.state.notifications.findIndex(function(notification) {
          return notification.id == id
        });
        store.state.notifications.splice(index, 1)
      })
    }
    store.state.enableNotification = false;
  }).catch((err) => {
    console.log(err);
    // store.state.enableNotification = false;
  });
}

export async function getNotifications(token) {

  return await axios
      .get(`${process.env.VUE_APP_URL}notifications`, {
        headers: { "Authorization-Token": token },
      })
      .then((response) => {
        console.log(response)
        store.state.notifications = response.data;
        localStorage.setItem("notifications", response.data)
        console.log(response.data)
        if (response.data.length > 0) {
          store.state.enableNotification = true;
         } else {
          store.state.enableNotification = false;
        }
      })
      .catch((err) => {
        console.log(err);
        store.state.enableNotification = false;
      });
}

export async function getBitrixDocuments(token, payload) {
  let options = {
    filters: payload.filters,
    search: payload.search,
  };
  return await axios
    .post(`${process.env.VUE_APP_URL}documents/bitrix_documents`, options, {
      // timeout: 120000, // таймаут в миллисекундах
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response)
      store.state.bitrixDocuments = response.data.documents;
    })
    .catch((err) => {
      console.log(err);
      console.log(1);
      store.state.visibleDocumentsLoadingIndicator = true;
      // if (err.response?.status == 401) {
      //   localStorage.clear()
      // location.reload()
      //   store.state.authStatus = false
      //   store.state.authStatusLS = false
      // }
    });
}

export async function getInformationBlocks(token) {
  return await axios
    .get(`${process.env.VUE_APP_URL}information_blocks`, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response)
      const informationBlocks = response.data.information_blocks;

      const statusMapping = {
        bronze: {
          informationStatus: "Бронза",
          informationStatusColor: "#cd7f32",
        },
        silver: {
          informationStatus: "Серебро",
          informationStatusColor: "#E5E7EB",
        },
        gold: {
          informationStatus: "Золото",
          informationStatusColor: "#FAE100",
        },
      };

      const statusInfo = statusMapping[informationBlocks.status];

      store.state.informationStatus = statusInfo.informationStatus;
      store.state.informationStatusColor = statusInfo.informationStatusColor;
      store.state.informationBlocks = response.data.information_blocks;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getRaitingApi(token) {
  return await axios
    .get(`${process.env.VUE_APP_URL}users/rating`, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response);
      return response.data.rating;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getDetailDealApi(token, dealUrl) {
  return await axios
    .get(`${process.env.VUE_APP_URL}${dealUrl}`, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response);
      document.body.style = "overflow: hidden;";
      return response.data.deal;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getDetailCompanies(token) {
  return await axios
    .get(`${process.env.VUE_APP_URL}company/dependent`, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getCities(val) {
  return await axios
    .get(`${process.env.VUE_APP_URL}api/cities?val=${encodeURIComponent(val)}`)
    .then((response) => {
      // console.log(response);
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getChatFile(token, id) {
  return await axios
    .get(`${process.env.VUE_APP_URL}documents/document_id_for_chat?id=${id}`, {
      headers: { "Authorization-Token": token },
    })
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getChatFileSecond(id) {
  return await axios
    .get(
      `https://b24.datrans.ru/rest/9229/sajoe4wyuxh74yyj/disk.file.getexternallink.json?id=${id}`
    )
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}

export async function getChatFileSecond2(id) {
  return await axios
    .get(
      `https://b24.datrans.ru/rest/9229/sajoe4wyuxh74yyj/disk.file.getexternallink.json?id=${id}`
    )
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status == 401) {
        localStorage.clear();
        location.reload();
        store.state.authStatus = false;
        store.state.authStatusLS = false;
      }
    });
}
