import { createStore } from 'vuex';

export default createStore({
  state: {
    lastDeals: [],
    deals: [],
    informationBlocks: [],
    informationStatus: null,
    informationStatusColor: null,
    userData: [],
    profileDocuments: [],
    userDocuments: [],
    bitrixDocuments: [],
    token: localStorage.token,
    chatMessages: localStorage.chatMessages,
    authStatus: false,
    authStatusLS: localStorage.authStatus,
    headerData: null,
    notifications: [],
    orderCreateModalDocuments: [],
    visibleDocumentsLoadingIndicator: false,
    enableNotification: false,
    dealItem: null,
    _newMessagesCount: 0,
  },
  getters: {
    newMessagesCount(state) {
      return state._newMessagesCount;
    },
    lastDeals(state) {
      return state.lastDeals;
    },
    deals(state) {
      return state.deals;
    },
    informationBlocks(state) {
      return state.informationBlocks;
    },
    userData(state) {
      return state.userData;
    },
    profileDocuments(state) {
      return state.profileDocuments;
    },
    userDocuments(state) {
      return state.userDocuments;
    },
    BitrixDocuments(state) {
      return state.bitrixDocuments;
    },
    headerData(state) {
      return state.headerData;
    },
    orderCreateModalDocuments(state) {
      return state.orderCreateModalDocuments;
    },
    notifications(state) {
      return state.notifications;
    },
    enableNotification(state) {
      return state.enableNotification;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
