<template>
  <div class="modal-bg">
    <div class="modal-content">
        <div class="modal-header">
            <h2 class="body-orderpopup__title">Подтверждение заявки</h2>
            <p @click="openConfirmOrder"><img src="@/assets/icons/close.svg" alt="close"></p>
        </div>
        <div class="modal-body" style="margin-top:20px;">
          <p class="item">Номер заявки: {{ this.number_deal }}</p>
          <p class="item">Маршрут: {{ this.dealItem.route_from }} - {{ this.dealItem.route_to }}</p>
          <p class="item">Сумма: {{ this.dealItem[this.dealItem.selected_opportunity_field].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} ₽</p>
            <div class="logreg__body" v-if="!visibleConfirmForm">
                <div class="logreg__line">
                    <!-- <input
                    type="text"
                    style="margin-right:15px;"
                    placeholder="Номер телефона"
                    class="logreg__input"
                    maxlength="50"
                    > -->
                    <button class="details-orderpopup__redbtn" @click="sendConfirmation">Отправить код на номер телефона</button>
                </div>
            </div>
            <div v-if="resultMessage == 'Сделка успешно подтверждена'">
                <p style="text-align: center; font-size: 20px; font-weight: 600; margin: 30px 20px;">Заявка успешно подтверждена</p>
            </div>
            <div class="logreg__body" v-if="visibleConfirmForm && resultMessage != 'Сделка успешно подтверждена'">
                <p v-if="resultMessage">{{ resultMessage }}</p>
                <div class="logreg__line">
                    <div>
                        <input
                        type="text"
                        style="margin-right:15px;"
                        placeholder="Введите код"
                        class="logreg__input"
                        maxlength="4"
                        v-model="code"
                        >
                        <p style="cursor:pointer; margin-top: 10px; font-weight: 600;" @click="sendConfirmation">Отправить код еще раз <span v-if="timer">({{timer}} сек.)</span></p>
                    </div>
                    <button :class="isValidInput ? 'details-orderpopup__redbtn' : 'details-orderpopup__reset'" @click="checkConfirmation">Подтвердить</button>
                </div>
            </div>
        </div>
        <div class="loading" v-if="visibleLoadingIndicator">
            <LoadingIndicator/>
        </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "@/components/UI/LoadingIndicator.vue"
import axios from 'axios'
export default {
    props: ['openConfirmOrder', 'number_deal', 'getDetailDeal', "dealItem",],
    components: {
        LoadingIndicator
    },
    data() {
        return {
            visibleConfirmForm: false,
            code: null,
            resultMessage: null,
            visibleLoadingIndicator: false,
            timer: null,
            isValidInput: false
        }
    },
    methods: {
        async sendConfirmation() {
            let token = localStorage.getItem('token')
			let options = {
                'Authorization-Token': token,
                'deal_id': this.number_deal
            }
			let url = process.env.VUE_APP_URL
            if (!this.timer) {
                await axios.post(url + 'deal/send_confirmation_code', options)
                .then(response => {
                    // console.log(response)
                    this.visibleConfirmForm = true
                    this.resultMessage = 'Код успешно отправлен на ваш номер телефона'
                    this.timer = 30;

                    // Запуск обратного отсчета таймера
                    setInterval(() => {
                    if (this.timer > 0) {
                        this.timer--;
                    }
                    }, 1000);
                })
                .catch(err => {
                    console.log(err);
                })
            }
        },
        async checkConfirmation() {
            this.visibleLoadingIndicator = true
            let token = localStorage.getItem('token')
			let options = {
                'Authorization-Token': token,
                'deal_id': this.number_deal,
                'code': this.code
            }
			let url = process.env.VUE_APP_URL
            if (this.isValidInput) {
                await axios.post(url + 'deal/check_confirmation_code', options)
                .then(response => {
                    // console.log(response)
                    if(response.data.result == true) {
                        this.visibleLoadingIndicator = false
                        this.resultMessage = 'Сделка успешно подтверждена'
                        this.openConfirmOrder()
                        this.getDetailDeal()
                    }
                    if(response.data.result == false) {
                        this.visibleLoadingIndicator = false
                        this.resultMessage = 'Код неверный'
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.visibleLoadingIndicator = false
                })
            }
        }
    },
    watch: {
        code() {
            if (this.code.length == 4) {
                this.isValidInput = true
            } else {
                this.isValidInput = false
            }
        }
    }
}
</script>

<style>
.item{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
  color: rgba(19, 19, 19, 0.5);
  margin: 5px;
}
.modal-bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.163);
}
.modal-content{
    margin: 0 auto;
    background-color: #fff;
    padding: 15px;
    width: fit-content;
    margin-top: 200px;
    position: relative;
}
.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.loading{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.35);
    text-align: center;
}
</style>