<template>
    <div id="orderpopup" class="popup orderpopup">
        <div class="popup__wrapper orderpopup__wrapper">
            <div class="popup__content orderpopup__content" style="padding: 0;">
                <button data-close type="button" class="popup__close orderpopup__close" @click="openModal">
                    <img src="@/assets/icons/close.svg" alt="close">
                </button>
                <section class="page__map map">
                    <div class="map__container" style="padding: 0; max-width: 100rem;">
                        <div class="map__wrapperr" style="padding: 0;" ref="mapContainer" id="map" :style="`width:${width};`"></div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import TrackingMap from '@/components/tracking-view/TrackingMap.vue'
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat, getCenter } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { getLastDeals } from '@/api/get'

export default {
    props: ['openModal', 'deal'],
    components: {TrackingMap},
    data() {
        return {
        lastDeals: this.$store.state.lastDeals,
        markerFeatures: []
        }
    },
    mounted() {
        getLastDeals(localStorage.getItem('token'), { limit: 'all', filters: [], search: '' }).then(response => {
        this.initializeMap();
        this.addMarkers();
        })
    },
    methods: {
        initializeMap() {
        const map = new Map({
            target: this.$refs.mapContainer,
            layers: [
            new TileLayer({
                source: new OSM()
            })
            ],
            view: new View({
            center: fromLonLat([0, 0]), // Значение по умолчанию
            zoom: 12
            })
        });
        this.map = map;

        if (this.deal !== null && this.deal.movizor) {
            const { lon, lat } = this.deal.movizor;
            const center = lon && lat ? fromLonLat([lon, lat]) : fromLonLat([0, 0]);
            map.getView().setCenter(center);
            // console.log(1);
        }
        },
        addMarkers() {
        if (this.deal && this.deal.movizor) {
            this.markerFeatures.push({
                id: 1,
                lon: this.deal.movizor.lon,
                lat: this.deal.movizor.lat
            });
        }

        const markerSource = new VectorSource();

        this.markerFeatures.forEach(marker => {
            const { lon, lat, id } = marker;
            const markerPoint = new Point(fromLonLat([lon, lat]));
            const markerFeature = new Feature({
            geometry: markerPoint,
            id: id
            });
            const markerStyle = new Style({
            image: new Icon({
                src: require('@/assets/marker-icon-2x.png'), // Путь к иконке маркера
                scale: 0.5
            })
            });
            markerFeature.setStyle(markerStyle);
            markerSource.addFeature(markerFeature);
        });

        const markerLayer = new VectorLayer({
            source: markerSource
        });

        this.map.addLayer(markerLayer);
        }
    }
}
</script>

<style scoped>
.popup.orderpopup {
    z-index: 1150;
    visibility: visible;
    overflow: auto;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.403);
}

.orderpopup__content {
    transform: scale(1);
    visibility: visible;
}
[class*=__wrapperr]{
    max-width: 100vw !important;
}
.map {
    width: 100%;
    height: 65vh;
}
.popup__close.orderpopup__close{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    background: transparent;
}
</style>