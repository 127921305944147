<template>
  <vue-resizable :min-width="366" :min-height="443" style="position: absolute">
    <div class="spollers__body chat-header__body body-chat resizable-content">
      <div class="body-chat__head">
        <div class="body-chat__manager">
          <div class="body-chat__ava">
            <picture
              ><source :srcset="headerData?.pm_photo" type="image/webp" />
              <img
                :src="headerData?.pm_photo"
                alt="manager"
                style="
                  border-radius: 100%;
                  width: 48px;
                  height: 48px;
                  object-fit: cover;
                "
            /></picture>
          </div>
          <div class="body-chat__text">
            {{ headerData?.pm_name }} <br />
            {{ headerData?.pm_last_name }}
          </div>
          <!-- <a href="https://b24.datrans.ru/bitrix/services/main/ajax.php?action=disk.api.file.download&amp;SITE_ID=s1&amp;humanRE=1&amp;fileId=2070461&amp;fileName=311aa.txt" target="_blank">aii</a> -->
        </div>
        <!-- <button type="button" class="spollers__title body-chat__notifications" @click="openNotifications">
                <img src="@/assets/header/header_bell.svg" alt="notifications">
            </button> -->
        <div class="body-chat__close" @click="openChat">
          <img src="@/assets/orders/order_close.svg" alt="close" />
        </div>
      </div>
      <div class="body-chat__body" id="body-chat__body" ref="body_chat">
        <!--      <div v-if="messages.length">-->
        <!--        <div-->
        <!--          class="body-chat__message message-chat body-chat__message_incoming"-->
        <!--        >-->
        <!--          <div class="message-chat__text"></div>-->
        <!--          <div class="message-chat__info">-->
        <!--            <div class="message-chat__name">Goroxxx</div>-->
        <!--            <div class="message-chat__time">10:00</div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!-- <div style="text-align:center;" v-else>Сообщений в чате пока что нету</div> -->
        <!-- <div class="body-chat__message message-chat body-chat__message_outcoming">
                <div class="message-chat__text">Привет</div>
                <div class="message-chat__info">
                    <div class="message-chat__name">Goroxxx</div>
                    <div class="message-chat__time">10:00</div>
                </div>
            </div> -->
      </div>
      <form class="body-chat__form form-chat form_chat_position">
        <div class="form-chat__file">
          <label
            class="form-chat__fileLabel input-file"
            style="position: relative"
          >
            <input
              type="file"
              multiple
              id="js-file"
              class="form-chat__fileinput"
              ref="fileChat"
              @change="changeFile"
              tabindex="-1"
            />
            <!-- <img src="@/assets/header/header_plus.svg" alt="plus"> -->
            <span
              ><img src="@/assets/header/header_plus.svg" alt="plus"
            /></span>
            <p v-if="fileChatt !== null" class="form-chat__file-span">1</p>
          </label>
        </div>
        <input
          type="text"
          class="form-chat__input"
          data-required
          placeholder="Введите сообщение"
          v-model="textMessage"
        />
        <button
          data-tippy-content="Отправить"
          type="submit"
          class="form-chat__button"
          :style="`opacity:${sendingMessage ? '40%' : '100%'}`"
          @click="sendMessage"
        >
          <img src="@/assets/icons/chat_send.svg" alt="send" />
        </button>
      </form>
    </div>
  </vue-resizable>
</template>

<script>
import HeaderChatItem from '@/components/HeaderChatItem.vue';
import { getChatFile, getChatFileSecond } from '@/api/get';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import VueResizable from 'vue-resizable';
import messageSound from '@/assets/sounds/message.mp3';

const getMessagesURL =
  'https://b24.datrans.ru/rest/im.dialog.messages.get.json?logTag=im.dialog.messages.get&timDevice=web';

const code = 'jXWtiDuG';

async function getFileInfo(file_id) {
  const response = await axios
    .get(
      'https://b24.datrans.ru/rest/9229/5lz1y3ey7o2qrmhf/d1vine.get_external_file.json?id=' +
        file_id
    )
    .catch((err) => {
      console.error('error get file info', err.message);
    });
  return response?.data.result.response;
}

export default {
  props: ['openNotifications', 'openChat'],
  components: {
    HeaderChatItem,
    VueResizable,
  },
  data() {
    return {
      textMessage: null,
      messages: JSON.parse(localStorage.getItem('chatMessage') || '[]'),
      fileChatt: null,
      chat_id_plus: null,
      message_sound: new Audio(messageSound),
      sendingMessage: false,
      // chunkSizeInBytes: null,
      // fileData1: null,
      // readOffset: null
    };
  },
  methods: {
    async updateMessages() {
      const answerData = new FormData();
      const livechat_hash = $cookies.get('LIVECHAT_HASH');
      answerData.append('livechat_auth_id', livechat_hash);
      answerData.append('DIALOG_ID', 'chat' + $cookies.get('CHAT_ID'));
      answerData.append('FIRST_ID', 0);
      answerData.append('LIMIT', 100);
      const response = await axios
        .post(getMessagesURL, answerData)
        .catch((err) => {
          console.log(err);
        });
      if (!response || !this.headerData) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.updateMessages();
        return;
      }
      this.messages = response.data.result.messages || [];
      const files = response.data.result.files;
      // console.log('header data', this.headerData);

      //нельзя такие большие данные пихать в кукисы. да и вообще смысла нет сообщения хранить в браузере. пущай всегда получаются с сервера
      //к тому же там и так постоянно вся переписка приходит
      // $cookies.set(
      //   'chatMessage',
      //   JSON.stringify(response.data.result.messages)
      // );
      const user_id = $cookies.get('USER_ID');
      const oldMessages = JSON.parse(localStorage.getItem('chatMessage'));
      localStorage.setItem('chatMessage', JSON.stringify(this.messages));

      if (oldMessages?.length && this.messages?.length) {
        let newMessagesCount = this.messages.filter(
          (message) =>
            message.author_id != user_id &&
            !oldMessages.find((om) => om.id == message.id)
        ).length;
        if (newMessagesCount) {
          this.message_sound.play();
          toast(`Новые сообщения в чате: ${newMessagesCount}`);
          this.$store.state._newMessagesCount = newMessagesCount;
        }
      }
      // $('.body-chat__body').innerHTML = '';

      // вот это заполнение по уму сделать надо средствами вью
      this.messages.forEach((message) => {
        let dateee = new Date(message['date']);
        let dateMin = dateee.getMinutes().toString().padStart(2, '0');
        let dateHour = dateee.getHours().toString().padStart(2, '0');
        let messageDate = dateHour + ':' + dateMin;
        if (
          (!message.author_id && !document.getElementById(message['id'])) ||
          (message.author_id && !document.getElementById(message['id']))
        ) {
          const contact_name =
            user_id != message.author_id
              ? this.headerData.pm_name + ' ' + this.headerData.pm_last_name
              : this.headerData.contact_name;
          let text = message.text;
          let fileId = '',
            type = 'text';
          if (message.params.FILE_ID && files?.length) {
            let file = files.find((f) => (f.id = message.params.FILE_ID));
            type = file.type;
            fileId = file.id;
            if (type === 'image') {
              //text = `<a href="${info.url}">file.name</a>`;
              getFileInfo(fileId).then((info) => {
                // console.log('file info', info);
                console.log(document.querySelector(`#chat_image_${fileId}`));
                document.querySelector(`#chat_image_${fileId}`).src = info.url;
                document.querySelector(`#chat_image_link_${fileId}`).href =
                  info.url;
              });
            }
          }
          $('.body-chat__body').append(
            '<div class="body-chat__message message-chat body-chat__message_' +
              (user_id != message.author_id ? 'incoming' : 'outcoming') +
              '" id=' +
              message.id +
              '>' +
              (type == 'image'
                ? `<div class="message-chat__image"><a target="_blank" id="chat_image_link_${fileId}"><img id="chat_image_${fileId}" src="" /></a></div>`
                : '') +
              '<div class="message-chat__text">' +
              text +
              '</div>' +
              '<div class="message-chat__info">' +
              '<div class="message-chat__name">' +
              contact_name +
              '</div>' +
              '<div class="message-chat__time">' +
              messageDate +
              '</div>' +
              '</div>' +
              '</div>'
          );
        }
      });
      await new Promise((resolve) => setTimeout(resolve, 8000));

      this.updateMessages();
    },
    // пока не понятен смысл этой функции до конца. возможно она и не нужна. зачем этот запрос на imopenlines?
    async initDefault() {
      const initDefaultData = new FormData();
      initDefaultData.append('livechat_auth_id', 'guest');
      initDefaultData.append('code', code);
      await axios
        .post(
          'https://b24.datrans.ru/rest/imopenlines.widget.config.get.json?logTag=imopenlines.widget.config.get&timDevice=web',
          initDefaultData
        )
        .then((response) => {
          // console.log(response)
          return;
          if ($cookies.get('LIVECHAT_HASH')) {
            const answerData = new FormData();
            answerData.append(
              'livechat_auth_id',
              $cookies.get('LIVECHAT_HASH')
            );
            answerData.append('DIALOG_ID', 'chat' + $cookies.get('CHAT_ID'));
            answerData.append('FIRST_ID', 0);
            answerData.append('LIMIT', 100);
            axios
              .post(getMessagesURL, answerData)
              .then((response) => {
                // console.log(response);
                for (
                  let i = 0;
                  i < response.data.result['messages'].length;
                  i++
                ) {
                  let datee = Date.parse(
                    response.data.result['messages'][i]['date']
                  );
                  let dateee = new Date(datee);
                  let dateMin = String(dateee.getMinutes());
                  let dateHour = String(dateee.getHours());
                  if (dateMin.length == 1) {
                    dateMin = '0' + String(dateee.getMinutes());
                  }
                  if (dateHour.length == 1) {
                    dateHour = '0' + String(dateee.getHours());
                  }
                  let messageDate = dateHour + ':' + dateMin;
                  if (
                    response.data.result['messages'][i]['author_id'] == 0 &&
                    !document.getElementById(
                      response.data.result['messages'][i]['id']
                    )
                  ) {
                    $('.body-chat__body').append(
                      '<div class="body-chat__message message-chat body-chat__message_incoming" id=' +
                        response.data.result['messages'][i]['id'] +
                        '>' +
                        '<div class="message-chat__text">' +
                        response.data.result['messages'][i]['text'] +
                        '</div>' +
                        '<div class="message-chat__info">' +
                        '<div class="message-chat__name">' +
                        this.headerData.pm_name +
                        ' ' +
                        this.headerData.pm_last_name +
                        '</div>' +
                        '<div class="message-chat__time">' +
                        messageDate +
                        '</div>' +
                        '</div>' +
                        '</div>'
                    );
                  }
                  if (
                    $cookies.get('USER_ID') !=
                      response.data.result['messages'][i]['author_id'] &&
                    response.data.result['messages'][i]['author_id'] !== 0
                  ) {
                    if (
                      !document.getElementById(
                        response.data.result['messages'][i]['id']
                      ) &&
                      response.data.result['messages'][i]['text'] != ''
                    ) {
                      $('.body-chat__body').append(
                        '<div class="body-chat__message message-chat body-chat__message_incoming" id=' +
                          response.data.result['messages'][i]['id'] +
                          '>' +
                          '<div class="message-chat__text">' +
                          response.data.result['messages'][i]['text'] +
                          '</div>' +
                          '<div class="message-chat__info">' +
                          '<div class="message-chat__name">' +
                          this.headerData.pm_name +
                          ' ' +
                          this.headerData.pm_last_name +
                          '</div>' +
                          '<div class="message-chat__time">' +
                          messageDate +
                          '</div>' +
                          '</div>' +
                          '</div>'
                      );
                    }
                    for (
                      let a = 0;
                      a < response.data.result['files'].length;
                      a++
                    ) {
                      if (
                        response.data.result['messages'][i]['params']['FILE_ID']
                      ) {
                        if (
                          $cookies.get('USER_ID') !=
                            response.data.result['messages'][i]['author_id'] &&
                          response.data.result['messages'][i]['author_id'] !== 0
                        ) {
                          if (
                            response.data.result['messages'][i]['params'][
                              'FILE_ID'
                            ] == response.data.result['files'][a]['id']
                          ) {
                            // $('.body-chat__body').append('<div class="body-chat__message message-chat body-chat__message_incoming" id=' + response.data.result['messages'][a]['id'] + '>' +
                            //     '<div class="message-chat__text">' + response.data.result["files"][a]["name"] + '<br/><a href="#" data-file-id="'+ response.data.result["files"][a]["id"] +'" "> Скачать</a>' + '</div>' +
                            //     '<div class="message-chat__info">' +
                            //         '<div class="message-chat__name">' + this.headerData.pm_name + ' ' + this.headerData.pm_last_name + '</div>' +
                            //         '<div class="message-chat__time">' + messageDate + '</div>' +
                            //     '</div>' +
                            //     '</div>'
                            // );
                            axios
                              .get(
                                'https://b24.datrans.ru/rest/9229/5lz1y3ey7o2qrmhf/d1vine.get_external_file.json?id=' +
                                  response.data.result['files'][a]['id']
                              )
                              .then((file_response) => {
                                // window.location.assign(response.data.result.response.url);
                                $('.body-chat__body').append(
                                  '<div class="body-chat__message message-chat body-chat__message_incoming" id=' +
                                    response.data.result['messages'][a]['id'] +
                                    ' style="cursor: pointer">' +
                                    // onclick="location.href=\'https://b24.datrans.ru/rest/9229/5lz1y3ey7o2qrmhf/d1vine.get_external_file.json?id=' + response.data.result["files"][a]["id"] + '\';"
                                    '<a href="' +
                                    file_response.data.result.response.url +
                                    '" download>' +
                                    response.data.result['files'][a]['name'] +
                                    '</a>' +
                                    '<div class="message-chat__text" style="color: blue">' +
                                    response.data.result['files'][a]['name'] +
                                    '</div>' +
                                    '<div class="message-chat__info">' +
                                    '<div class="message-chat__name">' +
                                    this.headerData.pm_name +
                                    ' ' +
                                    this.headerData.pm_last_name +
                                    '</div>' +
                                    '<div class="message-chat__time">' +
                                    messageDate +
                                    '</div>' +
                                    '</div>' +
                                    '</div>'
                                );
                                window.location.href =
                                  response.data.result.response.url;
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                            // $('.body-chat__body').append('<div class="body-chat__message message-chat body-chat__message_incoming" id=' + response.data.result['messages'][a]['id'] + ' style="cursor: pointer">' +
                            // // onclick="location.href=\'https://b24.datrans.ru/rest/9229/5lz1y3ey7o2qrmhf/d1vine.get_external_file.json?id=' + response.data.result["files"][a]["id"] + '\';"
                            //     '<div class="message-chat__text" style="color: blue">' + response.data.result["files"][a]["name"] + '</div>' +
                            //     '<div class="message-chat__info">' +
                            //         '<div class="message-chat__name">' + this.headerData.pm_name + ' ' + this.headerData.pm_last_name + '</div>' +
                            //         '<div class="message-chat__time">' + messageDate + '</div>' +
                            //     '</div>' +
                            //     '</div>'
                            // );
                            // Добавляем обработчики событий для ссылки на файл
                            // this.addEventListeners(response.data.result["files"][a]["id"], response.data.result["files"]);
                            // document.getElementById(response.data.result["messages"][a]["id"].toString()).onclick = function () {
                            //   axios.get('https://b24.datrans.ru/rest/9229/5lz1y3ey7o2qrmhf/d1vine.get_external_file.json?id=' + response.data.result["files"][a]["id"])
                            //       .then(response => {
                            //         // window.location.assign(response.data.result.response.url);
                            //
                            //         window.location.href = response.data.result.response.url
                            //       })
                            //       .catch(err => {
                            //         console.log(err);
                            //       })
                            // };
                          }
                        }
                      }
                    }
                  }
                  if (
                    $cookies.get('USER_ID') ==
                      response.data.result['messages'][i]['author_id'] &&
                    response.data.result['messages'][i]['author_id'] !== 0
                  ) {
                    if (
                      !document.getElementById(
                        response.data.result['messages'][i]['id']
                      ) &&
                      response.data.result['messages'][i]['text'] != ''
                    ) {
                      $('.body-chat__body').append(
                        '<div class="body-chat__message message-chat body-chat__message_outcoming" id=' +
                          response.data.result +
                          '>' +
                          '<div class="message-chat__text">' +
                          response.data.result['messages'][i]['text'] +
                          '</div>' +
                          '<div class="message-chat__info">' +
                          '<div class="message-chat__name">' +
                          this.headerData.contact_name +
                          '</div>' +
                          '<div class="message-chat__time">' +
                          messageDate +
                          '</div>' +
                          '</div>' +
                          '</div>'
                      );
                    }
                    for (
                      let b = 0;
                      b < response.data.result['files'].length;
                      b++
                    ) {
                      if (
                        response.data.result['messages'][i]['params']['FILE_ID']
                      ) {
                        if (
                          $cookies.get('USER_ID') ==
                          response.data.result['messages'][i]['author_id']
                        ) {
                          if (
                            response.data.result['messages'][i]['params'][
                              'FILE_ID'
                            ] == response.data.result['files'][b]['id']
                          ) {
                            const fileId =
                              response.data.result['files'][b]['id'];
                            const fileName =
                              response.data.result['files'][b]['name'];
                            // console.log(fileId);
                            // const fileElement = `
                            // <div class="body-chat__message message-chat body-chat__message_outcoming" id="${response.data.result['messages'][b]['id']}">
                            // <div class="message-chat__text">${fileName}<br/><a href="#" class="download-link" data-file-id="${fileId}"> Скачать</a></div>
                            // <div class="message-chat__info">
                            //     <div class="message-chat__name">${this.headerData.contact_name}</div>
                            //     <div class="message-chat__time">${messageDate}</div>
                            // </div>
                            // </div>
                            // `;

                            const fileElement = `
                                        <div class="body-chat__message message-chat body-chat__message_outcoming" id="${response.data.result['messages'][b]['id']}">
                                        <div class="message-chat__text" onclick="location.href='https://b24.datrans.ru/rest/9229/5lz1y3ey7o2qrmhf/d1vine.get_external_file.json?id=' + ${fileId};">${fileName}</div>
                                        <div class="message-chat__info">
                                            <div class="message-chat__name">${this.headerData.contact_name}</div>
                                            <div class="message-chat__time">${messageDate}</div>
                                        </div>
                                        </div>
                                        `;

                            // Добавляем элемент файла в DOM
                            $('.body-chat__body').append(fileElement);

                            // Добавляем обработчики событий для ссылки на файл
                            this.addEventListeners(
                              fileId,
                              response.data.result['files']
                            );
                          }
                        }
                      }
                    }
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async sendMessage(e) {
      e.preventDefault();
      if (this.sendingMessage) return;
      const email = '';
      const name = this.headerData?.contact_name || `User`;
      const last_name = ``;
      this.sendingMessage = true;
      if (!$cookies.get('LIVECHAT_HASH') && this.textMessage) {
        const sendMessageData = new FormData();
        sendMessageData.append('livechat_auth_id', 'guest');
        sendMessageData.append('halt', 0);
        sendMessageData.append(
          'cmd[imopenlines.widget.config.get]',
          'imopenlines.widget.config.get?code=' + code
        );
        sendMessageData.append(
          'cmd[imopenlines.widget.user.register]',
          `imopenlines.widget.user.register?config_id=24&name=${name}&last_name=${last_name}&avatar=` +
            `&email=${email}&www=&gender=male&position=&user_hash=&consent_url=&trace_data=`
        );
        sendMessageData.append('cmd[server.time]', 'server.time?');
        sendMessageData.append(
          'cmd[imopenlines.widget.user.get]',
          'imopenlines.widget.user.get?'
        );
        sendMessageData.append(
          'cmd[pull.config.get]',
          'pull.config.get?CACHE=N'
        );
        await axios
          .post(
            'https://b24.datrans.ru/rest/batch.json?logTag=widget.init.config&timType=livechat&timDevice=web',
            sendMessageData
          )
          .then((response) => {
            // console.log(response)
            this.messages = [{ text: this.textMessage }];
            $cookies.set(
              'LIVECHAT_HASH',
              response.data.result.result['imopenlines.widget.user.register']
                .hash
            );
            $cookies.set(
              'CHAT_ID',
              response.data.result.result['imopenlines.widget.user.register']
                .chatId
            );
            $cookies.set(
              'USER_ID',
              response.data.result.result['imopenlines.widget.user.register'][
                'id'
              ]
            );
            const successData = new FormData();
            successData.append(
              'livechat_auth_id',
              response.data.result.result['imopenlines.widget.user.register'][
                'hash'
              ]
            );
            successData.append('TEMPLATE_ID', 'temporary16735939372540');
            successData.append(
              'CHAT_ID',
              response.data.result.result['imopenlines.widget.user.register'][
                'chatId'
              ]
            );
            successData.append('MESSAGE', this.textMessage);
            this.chat_id_plus =
              Number(
                response.data.result.result['imopenlines.widget.user.register'][
                  'chatId'
                ]
              ) + 1;
            axios
              .post(
                'https://b24.datrans.ru/rest/im.message.add.json?logTag=im.message.add&timMessageType=text&timType=livechat&timDevice=web',
                successData
              )
              .then((response) => {
                // console.log(response);
                let datee = Date.now();
                let dateee = new Date(datee);
                let dateMin = String(dateee.getMinutes());
                let dateHour = String(dateee.getHours());
                if (dateMin.length == 1) {
                  dateMin = '0' + String(dateee.getMinutes());
                }
                if (dateHour.length == 1) {
                  dateHour = '0' + String(dateee.getHours());
                }
                let messageDate = dateHour + ':' + dateMin;
                $('.body-chat__body').append(
                  '<div class="body-chat__message message-chat body-chat__message_outcoming" id=' +
                    response.data.result +
                    '>' +
                    '<div class="message-chat__text">' +
                    this.textMessage +
                    '</div>' +
                    '<div class="message-chat__info">' +
                    '<div class="message-chat__name">' +
                    this.headerData.contact_name +
                    '</div>' +
                    '<div class="message-chat__time">' +
                    messageDate +
                    '</div>' +
                    '</div>' +
                    '</div>'
                );
                this.textMessage = '';

                axios
                  .post(
                    'https://b24.datrans.ru/rest/574/z57ssjytqxhlfe2q/imopenlines.operator.transfer.json?CHAT_ID=' +
                      this.chat_id_plus +
                      '&TRANSFER_ID=' +
                      this.headerData.pm_id
                  )
                  .then((response) => {
                    // console.log(response);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                // this.watchMessages();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
        // function success() {
        //     const successData = new FormData();
        //     successData.append('livechat_auth_id', result.result.result["imopenlines.widget.user.register"]['hash'])
        //     successData.append('TEMPLATE_ID', 'temporary16735939372540')
        //     successData.append('CHAT_ID', result.result.result["imopenlines.widget.user.register"]['chatId'])
        //     successData.append('MESSAGE', this.textMessage)
        //     axios.post('https://b24.datrans.ru/rest/im.message.add.json?logTag=im.message.add&timMessageType=text&timType=livechat&timDevice=web', successData)
        //     .then(response => {
        // console.log(response);
        //     })
        //     .catch(err => {
        //         console.log(err);
        //     })
        // }
        this.updateMessages();
        this.sendingMessage = false;
      } else if (this.fileChatt) {
        let fileName = this.fileChatt.name;
        // console.log(1);
        // this.fileChatt = 1
        let contact_name_header = this.headerData.contact_name;

        let self = this;

        $.ajax({
          url: 'https://b24.datrans.ru/rest/im.disk.folder.get.json?logTag=im.disk.folder.get&timDevice=web',
          type: 'POST',
          dataType: 'json',
          data: {
            livechat_auth_id: $.cookie('LIVECHAT_HASH'),
            chat_id: $.cookie('CHAT_ID'),
          },
          success: function (result) {
            $.cookie('folder_id', result.result['ID']);
          },
        });
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(self.fileChatt);
        fileReader.onload = function () {
          let file = fileReader.result;
          this.readOffset = 0;
          this.chunkSizeInBytes = self.fileChatt.size;
          this.fileData = self.fileChatt;
          // console.log(2);
          let contentRangeHeader =
            'bytes ' +
            this.readOffset +
            '-' +
            (this.readOffset + this.chunkSizeInBytes - 1) +
            '/' +
            this.fileData.size;
          $.ajax({
            type: 'POST',
            url:
              'https://b24.datrans.ru/bitrix/services/main/ajax.php?action=imopenlines.widget.disk.upload&filename=' +
              fileName,
            headers: {
              'livechat-auth-id': $.cookie('LIVECHAT_HASH'),
              'livechat-dialog-id': 'chat' + $.cookie('CHAT_ID'),
              'Content-Range': contentRangeHeader,
            },
            cache: false,
            processData: false,
            contentType: false,
            data: file,
            success: function (msg) {
              $('#js-file').hide();
              $.ajax({
                type: 'POST',
                url:
                  'https://b24.datrans.ru/bitrix/services/main/ajax.php?action=imopenlines.widget.disk.commit&filename=' +
                  fileName +
                  '&folderId=' +
                  $.cookie('folder_id') +
                  '&contentId=' +
                  msg.data['token'] +
                  '&generateUniqueName=true',
                headers: {
                  'livechat-auth-id': $.cookie('LIVECHAT_HASH'),
                  'livechat-dialog-id': 'chat' + $.cookie('CHAT_ID'),
                },
                cache: false,
                processData: false,
                contentType: false,
                data: file,
                dataType: 'json',
                success: function (msg) {
                  // console.log(msg);
                  $.ajax({
                    url: 'https://b24.datrans.ru/rest/im.disk.file.commit.json?logTag=im.disk.file.commit&timDevice=web',
                    type: 'POST',
                    data: {
                      livechat_auth_id: $.cookie('LIVECHAT_HASH'),
                      template_id: 'temporary16745816947341',
                      upload_id: msg.data.file['id'],
                      file_template_id: 'temporary16745816947330',
                      message: '',
                      chat_id: $.cookie('CHAT_ID'),
                    },
                    success: function (res) {
                      // console.log(res);
                      let datee = Date.now();
                      let dateee = new Date(datee);
                      let dateMin = String(dateee.getMinutes());
                      let dateHour = String(dateee.getHours());
                      if (dateMin.length == 1) {
                        dateMin = '0' + String(dateee.getMinutes());
                      }
                      if (dateHour.length == 1) {
                        dateHour = '0' + String(dateee.getHours());
                      }
                      let messageDate = dateHour + ':' + dateMin;
                      $('.body-chat__body').append(
                        '<div class="body-chat__message message-chat body-chat__message_outcoming">' +
                          '<div class="message-chat__text">' +
                          fileName +
                          '</div>' +
                          '<div class="message-chat__info">' +
                          '<div class="message-chat__name">' +
                          contact_name_header +
                          '</div>' +
                          '<div class="message-chat__time">' +
                          messageDate +
                          '</div>' +
                          '</div>' +
                          '</div>'
                      );
                      // $("#js-file").value = null
                      // $("#js-file").target.value = null
                      // self.fileChatt = null
                      console.log(3);
                      self.fileChatt = null;
                      $('#body-chat__body').empty();
                      // self.initDefault();
                      // self.$refs.body_chat.scrollTop = self.$refs.body_chat.scrollHeight
                      // Получаем элемент с сообщениями чата
                      const chatBody = self.$refs.body_chat;
                      // Прокручиваем до самого низа
                      setTimeout(() => {
                        chatBody.scrollTop =
                          chatBody.scrollHeight - chatBody.clientHeight;
                      }, 700);
                    },
                  });
                },
              });
            },
          });
        };
        this.sendingMessage = false;
      } else if (this.textMessage) {
        const messageData = new FormData();
        messageData.append('livechat_auth_id', $cookies.get('LIVECHAT_HASH'));
        messageData.append('TEMPLATE_ID', 'temporary16735939372540');
        messageData.append('CHAT_ID', $cookies.get('CHAT_ID'));
        messageData.append('MESSAGE', this.textMessage);
        await axios
          .post(
            'https://b24.datrans.ru/rest/im.message.add.json?logTag=im.message.add&timMessageType=text&timType=livechat&timDevice=web',
            messageData
          )
          .then((response) => {
            // console.log(response)
            // this.messages.push({text: this.textMessage})
            let datee = Date.now();
            let dateee = new Date(datee);
            let dateMin = String(dateee.getMinutes());
            let dateHour = String(dateee.getHours());
            if (dateMin.length == 1) {
              dateMin = '0' + String(dateee.getMinutes());
            }
            if (dateHour.length == 1) {
              dateHour = '0' + String(dateee.getHours());
            }
            let messageDate = dateHour + ':' + dateMin;
            $('.body-chat__body').append(
              '<div class="body-chat__message message-chat body-chat__message_outcoming" id=' +
                response.data.result +
                '>' +
                '<div class="message-chat__text">' +
                this.textMessage +
                '</div>' +
                '<div class="message-chat__info">' +
                '<div class="message-chat__name">' +
                this.headerData.contact_name +
                '</div>' +
                '<div class="message-chat__time">' +
                messageDate +
                '</div>' +
                '</div>' +
                '</div>'
            );
            this.textMessage = '';
            // this.watchMessages();
          })
          .catch((err) => {
            console.log(err);
          });
        this.sendingMessage = false;
      }
    },
    changeFile(event) {
      this.fileChatt = event.target.files[0];
      event.target.value = null;
    },
    downloadChatFile(file) {
      getChatFile(localStorage.getItem('token'), file.id).then((response) => {
        getChatFileSecond(response.id).then((response) => {
          // console.log(response.result);
          const link = document.createElement('a');
          link.href = response.result;

          // Устанавливаем имя файла
          link.download = file.name;

          // Добавляем ссылку в DOM
          document.body.appendChild(link);

          // Имитируем клик по ссылке
          link.click();

          // Удаляем ссылку из DOM
          document.body.removeChild(link);
        });
      });
    },
    // addEventListeners(fileId, files) {
    //     // Находим ссылку на файл по ее id
    //     const downloadLink = $(`[data-file-id="${fileId}"]`);
    //
    //     // Добавляем обработчик событий "click" для ссылки
    //     downloadLink.on('click', () => {
    //         // Находим объект файла, по его id
    //         const file = files.find(f => f.id === fileId);
    //
    //         // Загружаем файл
    //         this.downloadChatFile(file);
    //     });
    // }
  },
  created() {
    // console.log('created');
    // this.initDefault();
  },

  updated() {
    // Получаем элемент с сообщениями чата
    const chatBody = this.$refs.body_chat;
    // Прокручиваем до самого низа
    chatBody.scrollTop = chatBody.scrollHeight - chatBody.clientHeight;
  },
  computed: {
    headerData() {
      return this.$store.getters.headerData;
    },
  },
  mounted() {
    if ($cookies.get('LIVECHAT_HASH')) {
      //LIVECHAT_HASH будет известен только тогда, когда будет отправлено сообщение.
      this.updateMessages();
    }
  },
};
</script>

<style scoped>
.body-chat__body::-webkit-scrollbar {
  width: 7px;
  background-color: #f9f9fd;
}
.body-chat__body::-webkit-scrollbar-thumb {
  background-color: #ba181b;
  border-radius: 5px;
}
.message-chat__text {
  word-wrap: break-word;
}
.message-chat__image img {
  width: 200px;
}
.form-chat__file-span {
  height: 14.4px;
  position: absolute;
  top: -8px;
  right: -7px;
  background-color: #000000;
  color: #fff;
  margin: 0;
  padding: 0;
  width: 14.4px;
  text-align: center;
  border-radius: 100%;
  font-size: 12px;
}

.resizable-content {
  height: 100%;
  width: 100%;
  position: absolute;
}

.form_chat_position {
  position: absolute;
  width: 100%;
  bottom: 10px;
  padding-right: 58px;
}
</style>
