<template>
    <div class="files-orderpopup__line" v-for="document in dealItem.deal_details"
            :key="document">
        <div class="files-orderpopup__name"><p :title="document.name" style="word-wrap: anywhere;">{{ document.simple_name ?? document.name }}</p></div>
        <div class="files-orderpopup__links">
            <!-- <a :href="document.url ?? document.data" class="files-orderpopup__link" target="_blank" :download="document.simple_mame ?? document.name">
                <img src="@/assets/orders/order_download.svg" alt="download">
            </a> -->
            <a :href="document.url ?? document.data" class="files-orderpopup__link" target="_blank">
                <img src="@/assets/orders/eye.png" alt="watch">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: ['dealItem'],
    methods: {
        deleteDocument(index) {
            this.orderCreateModalDocuments.splice(index, 1)
        }
    }
}
</script>

<style>

</style>