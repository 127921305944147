<template>
    <div class="preview-cargofile__line" v-if="orderCreateModalDocuments">
        <div class="preview-cargofile__icon">
            <img src="@/assets/orders/order_document.svg" alt="icon">
        </div>
        <div class="preview-cargofile__name">{{ document.name }}</div>
        <div class="preview-cargofile__links">
            <a :href="document.data" class="preview-cargofile__link" :download="document.name">
                <img src="@/assets/orders/order_download.svg" alt="download">
            </a>
            <div class="preview-cargofile__link preview-cargofile__link_delete" @click="deleteDocument(index)">
                <img src="@/assets/orders/order_close.svg" alt="delete">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['document', 'index'],
    methods: {
        deleteDocument(index) {
            this.orderCreateModalDocuments.splice(index, 1)
        }
    },
    computed: {
        orderCreateModalDocuments() {
			return this.$store.getters.orderCreateModalDocuments
		}
    }
}
</script>

<style>

</style>