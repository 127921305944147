<template>
    <div class="table-last__line" @click="getDetailDeal(deal)">
        <div class="table-last__item" style="flex: 0 0 20px;" @click.stop="showTooltip(deal.number_deal)" :ref='`tooltipTarget${deal.number_deal}`'>
            <img src="@/assets/deal-burger.svg" style="width: 15px;" alt="burger-icon">
                <div
                    v-show="isTooltipVisible"
                    :ref='`tooltip${deal.number_deal}`'
                    class="tooltip"
                    :style="tooltipStyle"
                >
                    <div class="tooltip_list" style="z-index: 100;">
                        <button @click.stop="getDetailDeal(deal, 'Детальный просмотр заявки', true)">Просмотр</button>
                        <button @click.stop="copyDetailDeal(deal)">Копировать</button>
                        <button @click.stop="getDetailDeal(deal, 'Редактирование заявки', false)" :disabled="!deal.from_lk || !deal.is_new">Редактировать</button>
                        <button @click.stop="deleteDeal(deal)" :disabled="!deal.from_lk || !deal.is_new">Удалить</button>
                    </div>
                    <div class="tooltip_background" style="z-index: 99;" @click.stop="hideTooltip"></div>
                </div>
        </div>
        <div class="table-last__item">
            №{{ deal.number_deal }}
        </div>
        <div class="table-last__item route-table">
            <div class="route-table__icon">
                <img src="@/assets/orders/orders_arrow.svg" alt="arrow">
            </div>
            <div class="route-table__body">
                <span class="route-table__city">{{ deal.route_from }}</span>
                <span class="route-table__city">{{ deal.route_to }}</span>
            </div>
        </div>
        <div class="table-last__item">
            {{ deal.upload_date }}
        </div>
        <div class="table-last__item status-table">
            <div class="status-table__circle" :style="{backgroundColor: deal.color}"></div>
            <div class="status-table__name">{{ deal.status }}</div>
        </div>
        <div class="table-last__item" style="text-align: center;">
            {{ deal.cargo_name }}
        </div>
        <div class="table-last__item" style="text-align: center;" v-if="parseDealOpportunity != 0">
            {{ parseDealOpportunity }}₽
        </div>
        <!-- <div class="table-last__item indicator-table" v-if="deal.attention == 1">
            <div class="indicator-table__image">
                <img src="@/assets/icons/warning.svg" alt="warning">
            </div>
        </div> -->
        <div class="table-last__item tracking-tabla" @click.stop="openModal">
            <a href="#" class="tracking-tabla__link" v-if="deal.movizor">
                <img src="@/assets/icons/eye.svg" alt="eye">
            </a>
        </div>
    </div>
    <TrackingModal
    v-if="visibleTrackingModal"
    :openModal="openModal"
    :deal="deal"
    />
</template>

<script>
import axios from 'axios'
import TrackingModal from '@/components/tracking-view/TrackingModal.vue'
import { getLastDeals, getDetailDealApi } from '@/api/get'

export default {
    props: ['deal', 'getDetailDeal', 'copyDetailDeal'],
    components: {TrackingModal},
    data() {
        return {
            visibleTrackingModal: false,
            isTooltipVisible: false,
            tooltipText: "Текст подсказки",
            tooltipStyle: {}
        }
    },
    methods: {
        openModal() {
            this.visibleTrackingModal = !this.visibleTrackingModal
        },
        async deleteDeal(deal) {
            await axios.delete(`${process.env.VUE_APP_URL}deal/${deal.number_deal}`, { headers: {'Authorization-Token': localStorage.getItem('token')} })
            .then(response => {
                getLastDeals(localStorage.getItem('token'), {limit: this.limit, filters: [], search: ''})
            })
            .catch(err => {
                console.log(err);
            })
        },
        showTooltip(id) {
            this.isTooltipVisible = true;
            this.updateTooltipPosition(id);
        },
        hideTooltip() {
            this.isTooltipVisible = false;
        },
        updateTooltipPosition(id) {
            const refi = `tooltipTarget${id}`;
            const targetElement = this.$refs[refi];
            const refi2 = `tooltip${id}`;
            const tooltipElement = this.$refs[refi2];

            const targetRect = targetElement.getBoundingClientRect();
            const tooltipRect = tooltipElement.getBoundingClientRect();

            const left = targetRect.left + targetRect.width / 2 - tooltipRect.width / 2;

            // Учет прокрутки страницы и позиционирование ниже элемента
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const bottom = window.innerHeight - targetRect.bottom + scrollTop + 10;

            // Позиционирование выше на 100px, если скролл до конца
            const tooltipTop = targetRect.top - tooltipRect.height - 148;

            this.tooltipStyle = {
                top: `${tooltipTop}px`,
                left: `${left}px`,
            };
        }
    },
    computed: {
        parseDealOpportunity() {
            let num = this.deal.opportunity ? this.deal.opportunity : null;
            if (num) {
                num = num.split(".")[0]
                num = num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            return num;
        }
    }
}
</script>

<style>
.tooltip {
    position: fixed;
    background-color: #ffffff;
    color: #000000;
    border-radius: 4px;
    padding: 10px 0px;
    font-size: 14px;
    z-index: 4;
}
.tooltip_list{
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 7px 21px rgba(83, 92, 105, .12), 0 -1px 6px 0 rgba(83, 92, 105, .06);
}
.tooltip_list button{
    display: block;
    padding: 10px 15px;
    width: 100%;
    text-align: left;
    color: #525c68;
}
.tooltip_list button:hover{
    background-color: #f5f5f6;
}
.tooltip_list button:disabled{
    background-color: #f5f5f69d;
    color: #525c68a1;
}
.tooltip_background{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
</style>