<template>
    <div data-simplebar class="last__body">
        <div class="table-last__line table-last__line_first">
            <div class="table-last__item" style="flex: 0 0 20px;"></div>
            <div class="table-last__item">
                № Заявки
            </div>
            <div class="table-last__item route-table">
                Маршрут
            </div>
            <div class="table-last__item"> 
                Дата
            </div>
            <div class="table-last__item">
                Статус
            </div>
            <div class="table-last__item">
                Наименование груза
            </div>
            <div class="table-last__item">
                Стоимость
            </div>
            <div class="table-last__item">
                Отслеживание
            </div>
        </div>
        <div data-simplebar class="last__table table-last ">
            <HomeLastItem
            v-for="deal in $store.getters.lastDeals"
            :key="deal.number_deal"
            :getDetailDeal="getDetailDeal"
            :deal="deal"
            :copyDetailDeal="copyDetailDeal"
            />
<!--          !$store.getters.lastDeals.length-->
            <LoadingIndicator
            v-if="visibleLoadingIndicator"
            />
          <div class="no-deals" v-if="!visibleLoadingIndicator && !$store.getters.lastDeals.length">У вас нет ни одной сделки</div>

        </div>
    </div>
    <OrderDetailModal 
    v-if="visibleDealItem" 
    :dealItem="dealItem"
    :visibleChange="visibleChange"
    :getDetailDeal="getDetailDeal"
    :orderDetailTitle="orderDetailTitle"
    :orderDetailReadonly="orderDetailReadonly"
    />
    <CopyDetailModal
    v-if="visibleCopyDealItem" 
    :dealItem="dealItem"
    :changeVisibleCopyDealItem="changeVisibleCopyDealItem"
    />
</template>

<script>
import HomeLastItem from '@/components/HomeLastItem.vue'
import OrderDetailModal from '@/components/OrderDetailModal.vue'
import LoadingIndicator from "@/components/UI/LoadingIndicator.vue"
import CopyDetailModal from '@/components/CopyDetailModal.vue'
import axios from 'axios'
import { getLastDeals, getDetailDealApi } from '@/api/get'

export default {
    props: ['limit'],
    components: {
        HomeLastItem,
        OrderDetailModal,
        LoadingIndicator,
        CopyDetailModal
    },
    data() {
        return {
            dealItem: null,
            visibleDealItem: false,
            visibleCopyDealItem: false,
            testDt: null,
            dealUrl: null,
            orderDetailTitle: null,
            orderDetailReadonly: false,
            visibleLoadingIndicator: true
        }
    },
    methods: {
        visibleChange() {
            this.visibleDealItem = !this.visibleDealItem
            // document.body.style = 'overflow: hidden;'
            document.body.style = 'overflow: auto;'
        },
        changeVisibleCopyDealItem () {
            this.visibleCopyDealItem = !this.visibleCopyDealItem
        },
        getDetailDeal(deal, text, readonly) {
            getDetailDealApi(localStorage.getItem('token'), deal ? deal.url : this.dealUrl).then(res => {
                this.dealUrl = deal ? deal.url : this.dealUrl
                this.dealItem = res
                this.orderDetailTitle = text
                this.orderDetailReadonly = readonly
                this.visibleDealItem = true
                // this.dealItem.loading_point = this.dealItem.loading_point.split('|')[0]
                // this.dealItem.unloading_point = this.dealItem.unloading_point.split('|')[0]
                this.dealItem.unloading_date = this.dealItem.unloading_date.split('.')[0]
                this.dealItem.loading_date = this.dealItem.loading_date.split('.')[0]
                this.dealItem.unloading_date = this.dealItem.unloading_date.replace('T', ' ')
                this.dealItem.loading_date = this.dealItem.loading_date.replace('T', ' ')
                if (this.dealItem.loading_point == 0) {
                    this.dealItem.loading_point.push(null)
                }
                if (this.dealItem.unloading_point == 0) {
                    this.dealItem.unloading_point.push(null)
                }
            })
        },
        copyDetailDeal(deal) {
            getDetailDealApi(localStorage.getItem('token'), deal ? deal.url : this.dealUrl).then(res => {
                this.dealUrl = deal ? deal.url : this.dealUrl
                this.dealItem = res
                this.visibleCopyDealItem = true
                // this.dealItem.loading_point = this.dealItem.loading_point.split('|')[0]
                // this.dealItem.unloading_point = this.dealItem.unloading_point.split('|')[0]
                this.dealItem.unloading_date = this.dealItem.unloading_date.split('.')[0]
                this.dealItem.loading_date = this.dealItem.loading_date.split('.')[0]
                this.dealItem.unloading_date = this.dealItem.unloading_date.replace('T', ' ')
                this.dealItem.loading_date = this.dealItem.loading_date.replace('T', ' ')
                if (this.dealItem.loading_point == 0) {
                    this.dealItem.loading_point.push(null)
                }
                if (this.dealItem.unloading_point == 0) {
                    this.dealItem.unloading_point.push(null)
                }
            })
        }
    },
    mounted() {
        getLastDeals(localStorage.getItem('token'), {limit: this.limit, filters: [], search: ''}).then(response => {
          this.visibleLoadingIndicator = false
        }
    )
    }
}
</script>

<style>
.table-last__modal-bg{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #00000058;
    z-index: 999;
    min-height: 100%;
}
.table-last__modal-content{
    background-color: #fff;
    margin: 0 auto;
    padding: 25px;
    width: 60vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.table-last__modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-last__modal-body{
    margin-top: 20px;
}
.form-profile__line line-profile{
    margin-top: 15px;
}
.table-last__modal-body div{
    margin-top: 10px;
}

.no-deals {
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  color: rgba(19, 19, 19, 0.5);
}
</style>