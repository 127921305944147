<template>
    <div class="preview-cargofile__line">
        <div class="preview-cargofile__icon">
            <img src="@/assets/orders/order_document.svg" alt="icon">
        </div>
        <div class="preview-cargofile__name">{{ document.name }}</div>
        <div class="preview-cargofile__links">
            <a :href="'data:application/octet-stream;base64,' + document.data" class="preview-cargofile__link" :download="document.name">
                <img src="@/assets/orders/order_download.svg" alt="download">
            </a>
            <a :href="document.url ?? document.data" class="files-orderpopup__link" target="_blank">
                <img src="@/assets/orders/eye.png" alt="watch">
            </a>
            <div v-if="!readonlyDealItem" class="preview-cargofile__link preview-cargofile__link_delete" @click="deleteDocument(index)">
                <img src="@/assets/orders/order_close.svg" alt="delete">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['document', 'index', 'readonlyDealItem'],
    methods: {
        deleteDocument(index) {
            // this.orderCreateModalDocuments.splice(index, 1)
            this.$emit('deleteDocument', index)
        },
        dataUrl(data) {
            return "data:application/octet-stream;base64," + data
        }
    }
}
</script>

<style>

</style>