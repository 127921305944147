<template>
  <div class="wrapper">
		<main class="page">
			<section class="page__info info">
				<div class="info__container">
					<div class="info__wrapperr">
            <div class="quantity__head">
              <div class="quantity__title">Статистика компании</div>
            </div>
						<div class="info__body">
							<div class="info__points points-info">
								<div class="points-info__body" style="min-width:275px; padding-right: 0">
									<div class="points-info__left">
										<div class="points-info__name">Баллы</div>
										<div class="points-info__points">{{ informationBlocks.points }}</div>
									</div>
									<div class="points-info__right">
										<div class="points-info__gold"></div>
										<div class="points-info__silver">{{ $store.state.informationStatus }}</div>
									</div>
                  <div class="points-info__question-mark">?
                    <span class="tooltiptext">Баллы, начисляемые за сделки</span>
                  </div>
								</div>
								<div class="points-info__range">
									<span style="height: 100%;" :style="{backgroundColor: $store.state.informationStatusColor}"></span>
								</div>
							</div>
							<ul class="info__items">
								<li class="info__item item-info">
									<i class="item-info__icon">
										<img src="@/assets/orders/orders_speed.svg" alt="icon">
									</i>
									<div class="item-info__text">Общий вес (кг)</div>
									<div class="item-info__number">{{ informationBlocks.sum_of_money }}</div>
								</li>
								<li class="info__item item-info">
									<i class="item-info__icon">
										<img src="@/assets/orders/orders_speed.svg" alt="icon">
									</i>
									<div class="item-info__text">Общий километраж</div>
									<div class="item-info__number">{{ informationBlocks.total_mileage }}</div>
								</li>
								<li class="info__item item-info">
									<i class="item-info__icon">
										<img src="@/assets/orders/orders_speed.svg" alt="icon">
									</i>
									<div class="item-info__text">Успешные рейсы</div>
									<div class="item-info__number">{{ informationBlocks.number_of_shipments }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			<section class="page__quantity quantity">
				<div class="quantity__container">
					<div class="quantity__wrapperr">
            <div class="quantity__head">
              <div class="quantity__title">Количество сделок</div>
            </div>
						<div class="quantity__items">
							<div class="quantity__item item-quantity" style="--col:#22C55E;">
								<div class="item-quantity__head" style="--col:#22C55E;">
									<div class="item-quantity__circle" style="--col:#22C55E;"></div>
									<div class="item-quantity__title" style="--col:#22C55E;">Новые</div>
								</div>
								<div class="item-quantity__body" style="--col:#22C55E; --bg:#F0FDF4;">
									<div class="item-quantity__number" style="--col:#22C55E;">{{ informationBlocks.count_new }}</div>
								</div>
							</div>
							<div class="quantity__item item-quantity" style="--col:#FACC15;">
								<div class="item-quantity__head" style="--col:#FACC15;">
									<div class="item-quantity__circle" style="--col:#FACC15;"></div>
									<div class="item-quantity__title" style="--col:#FACC15;">В работе</div>
								</div>
								<div class="item-quantity__body" style="--col:#FACC15; --bg:#FEFCE8;">
									<div class="item-quantity__number" style="--col:#FACC15;">{{ informationBlocks.count_on_work }}</div>
								</div>
							</div>
							<div class="quantity__item item-quantity" style="--col:#fa6115;">
								<div class="item-quantity__head" style="--col:#fa6115;">
									<div class="item-quantity__circle" style="--col:#fa6115;"></div>
									<div class="item-quantity__title" style="--col:#fa6115;">В пути</div>
								</div>
								<div class="item-quantity__body" style="--col:#fa6115; --bg:#feefe8;">
									<div class="item-quantity__number" style="--col:#fa6115;">{{ informationBlocks.count_on_way }}</div>
								</div>
							</div>
							<div class="quantity__item item-quantity" style="--col:#3B82F6;">
								<div class="item-quantity__head" style="--col:#3B82F6;">
									<div class="item-quantity__circle" style="--col:#3B82F6;"></div>
									<div class="item-quantity__title" style="--col:#3B82F6;">Выгружены</div>
								</div>
								<div class="item-quantity__body" style="--col:#3B82F6; --bg:#F7F8FF;">
									<div class="item-quantity__number" style="--col:#3B82F6;">{{ informationBlocks.count_unloaded }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="page__last last">
				<div class="last__container">
					<div class="last__wrapperr">
						<div class="last__head">
              <div style="display:flex;">
                <div class="last__title" style="padding-top: 10px;">Последние сделки</div>
                <div class="head-last__left">
                  <a href="#" data-popup=".orderpopup" class="head-last__new" @click="openOrderCreate">
                    Новая заявка
                  </a>
                </div>
              </div>
							<!-- <div data-spollers class="spollers last__spollers spollers-last">
								<div class="spollers__item spollers-last__item">
									<button type="button" data-spoller data-spoller-close class="spollers__title spollers-last__title" @click="openSpollers">
										<img src="@/assets/icons/gear.svg" alt="filters">
									</button>
									<div class="spollers__body spollers-last__body body-last" v-if="visibleSpollers">
										<div class="body-last__head">
											<div class="body-last__title">Фильтры</div>
										</div>
										<div class="body-last__body">
											<div class="body-last__line"><input type="checkbox" id="body-last__filter_1" class="body-last__checkbox"><label for="body-last__filter_1" class="body-last__label">Наименование</label></div>
											<div class="body-last__line"><input type="checkbox" id="body-last__filter_2" class="body-last__checkbox"><label for="body-last__filter_2" class="body-last__label">Наименование</label></div>
											<div class="body-last__line"><input type="checkbox" id="body-last__filter_3" class="body-last__checkbox"><label for="body-last__filter_3" class="body-last__label">Наименование</label></div>
											<div class="body-last__line"><input type="checkbox" id="body-last__filter_4" class="body-last__checkbox"><label for="body-last__filter_4" class="body-last__label">Наименование</label></div>
											<div class="body-last__line"><input type="checkbox" id="body-last__filter_5" class="body-last__checkbox"><label for="body-last__filter_5" class="body-last__label">Наименование</label></div>
										</div>
									</div>
								</div>
							</div> -->
						</div>
						<HomeLastTable :limit="10" />
					</div>
				</div>
			</section>
		</main>
		<footer class="footer">
			<div class="footer__container">
			</div>
		</footer>
	</div>
  <OrderCreateModal
      v-if="visibleOrderCreate"
      :openOrderCreate="openOrderCreate"
      @messageCreate="messageCreate"
  />
</template>

<script>
import HomeLastTable from '@/components/HomeLastTable.vue'
import { getInformationBlocks } from '@/api/get'
import OrderCreateModal from '@/components/OrderCreateModal.vue'

export default {
  components: {
    OrderCreateModal,
    HomeLastTable
  },
  data() {
    return {
      visibleSpollers: false,
      scope: null,
      amount_transfer: null,
      total_distance: null,
      count_deal: null,
      informationStatus: null,
      visibleOrderCreate: false
    }
  },
  methods: {
    openSpollers() {
      this.visibleSpollers = !this.visibleSpollers
    },
    closeSpollers() {
      this.visibleSpollers = false
    },
    openOrderCreate() {
      this.visibleOrderCreate = !this.visibleOrderCreate
      // document.body.style = 'overflow: hidden;'
      this.$store.state.orderCreateModalDocuments = []
      if(this.visibleOrderCreate) {
        document.body.style = 'overflow: auto;'
      }
    },
    messageCreate() {
      this.messageCreateText = "Заявка успешно создана"
    }
  },
  computed: {
    informationBlocks() {
      return this.$store.getters.informationBlocks
    }
  },
  mounted() {
	getInformationBlocks(localStorage.getItem('token'))
  }
}
</script>

<style>
.head-last__left {
  margin-left: 25px;
}
.points-info__question-mark {
  text-align: right

}
.points-info__question-mark .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.points-info__question-mark:hover .tooltiptext {
  visibility: visible;
}
</style>
