<template>
  <Header v-if="$store.state.authStatus == true & $route.meta.auth || $store.state.authStatusLS == 'true' & $route.meta.auth" />
  <router-view />
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  components: {
    Header
  },
}
</script>

<style>

</style>
