<template>
  <header class="header">
    <div class="header__container">
      <button
          type="button"
          style="outline: none"
          class="menu__icon icon-menu"
          @click="openHeaderLeft"
      >
        <span></span>
      </button>
      <div class="left-header__close" @click.self="openHeaderLeft()"></div>
      <div class="header__left left-header">
        <div class="left-header__image">
          <picture>
            <source srcset="@/assets/login_bg.webp" type="image/webp"/>
            <img src="@/assets/login_bg.png" alt="bg"/>
          </picture>
        </div>
        <router-link data-da=".header__body , 1440, 1" to="/">
          <div class="left-header__logo">
            <img src="@/assets/logo-new.svg" alt="logo"/>
          </div>
        </router-link>
        <router-link
            to="/profile"
            data-da=".left-header, 767, 1"
            class="header__user user-heder user-header-left"
        >
          <div class="user-heder__avatar">
            <picture>
              <source :srcset="headerData?.user_photo" type="image/webp"/>
              <img :src="headerData?.user_photo" alt="company"/>
            </picture>
          </div>
          <div class="user-heder__text">
            <div class="user-heder__name">
              {{ headerData?.contact_name }}
            </div>
            <div class="user-heder__role">
              {{ headerData?.title }}
            </div>
          </div>
        </router-link>
        <div class="left-header__menu menu">
          <nav class="menu__body">
            <ul class="menu__list">
              <li class="menu__item menu__item_home">
                <router-link
                    to="/"
                    class="menu__link link-menu"
                    @click.prevent="openHeaderLeft"
                >
                  <i class="link-menu__icon">
                    <img src="@/assets/header/menu_home.svg" alt="home"/>
                  </i>
                  <span class="link-menu__text">Главная</span>
                </router-link>
              </li>
              <li class="menu__item">
                <router-link
                    to="/order"
                    class="menu__link link-menu"
                    @click.prevent="openHeaderLeft"
                >
                  <i class="link-menu__icon">
                    <img src="@/assets/header/menu_orders.svg" alt="orders"/>
                  </i>
                  <span class="link-menu__text">Заявки</span>
                </router-link>
              </li>
              <li class="menu__item">
                <router-link
                    to="/documents"
                    class="menu__link link-menu"
                    @click.prevent="openHeaderLeft"
                >
                  <i class="link-menu__icon">
                    <img src="@/assets/header/menu_docs.svg" alt="docs"/>
                  </i>
                  <span class="link-menu__text">Документы</span>
                </router-link>
              </li>
              <li class="menu__item">
                <router-link
                    to="/tracking"
                    class="menu__link link-menu"
                    @click.prevent="openHeaderLeft"
                >
                  <i class="link-menu__icon">
                    <img
                        src="@/assets/header/menu_location.svg"
                        alt="location"
                    />
                  </i>
                  <span class="link-menu__text">Отслеживание</span>
                </router-link>
              </li>
              <li class="menu__item">
                <router-link
                    to="/information"
                    class="menu__link link-menu"
                    @click.prevent="openHeaderLeft"
                >
                  <i class="link-menu__icon">
                    <img src="@/assets/header/menu_info.svg" alt="info"/>
                  </i>
                  <span class="link-menu__text">Информация</span>
                </router-link>
              </li>
              <li class="menu__item">
                <router-link
                    to="/service"
                    class="menu__link link-menu"
                    @click.prevent="openHeaderLeft"
                >
                  <i class="link-menu__icon">
                    <img src="@/assets/header/menu_support.svg" alt="support"/>
                  </i>
                  <span class="link-menu__text">Поддержка</span>
                </router-link>
              </li>
              <li class="menu__item">
                <router-link
                    to="/profile"
                    class="menu__link link-menu"
                    @click.prevent="openHeaderLeft"
                >
                  <i class="link-menu__icon">
                    <img src="@/assets/header/menu_user.svg" alt="user"/>
                  </i>
                  <span class="link-menu__text">Профиль</span>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="left-header__rating rating rating_set">
          <div class="rating__title">
            Оценка нашей компании: {{ raitingOurCompany }}/5
          </div>
          <div class="rating__body">
            <div class="rating__items" @mouseleave="leaveRaiting">
              <p
                  class="rating__item-text"
                  v-for="(item, i) in raitingItems"
                  :key="item"
                  :class="{ 'rating__item--active': item.isActiveRaiting }"
                  ref="raiting_item"
                  @mouseenter="hoverRaiting(i)"
              >
                ★<input
                  type="radio"
                  class="rating__item"
                  :value="item.value"
                  name="rating"
                  @click="raitingChange(i)"
              />
              </p>
            </div>
          </div>
          <div class="rating__value">5</div>
        </div>
        <a
            href="#"
            data-da=".left-header, 767, 3"
            class="header__manager manager-header header__manager-left"
        >
          <div class="manager-header__name" style="text-align: left">
            {{ headerData?.pm_name }} <br/>
            {{ headerData?.pm_last_name }}
          </div>
          <div class="manager-header__avatar" v-if="headerData?.pm_photo != ''">
            <picture>
              <source :srcset="headerData?.pm_photo" type="image/webp"/>
              <img :src="headerData?.pm_photo" alt="manager"/>
            </picture>
          </div>
          <div class="manager-header__avatar" v-if="headerData?.pm_photo == ''">
            <picture
            >
              <source srcset="@/assets/user-photo.png" type="image/webp"/>
              <img src="@/assets/user-photo.png" alt="manager"
              /></picture>
          </div>
        </a>
      </div>
      <div class="header__body header__wrapperr">
        <div data-spollers data-one-spoller class="spollers header__spollers">
          <router-link
              to="/profile"
              data-da=".left-header, 767, 1"
              class="header__user user-heder header__user-top"
          >
            <div class="user-heder__avatar">
              <picture>
                <source :srcset="headerData?.user_photo" type="image/png"/>
                <img
                    v-if="!headerData?.user_photo"
                    src="@/assets/company.png"
                    style="max-height: 48px"
                    alt="avatar"
                />
                <img
                    v-else
                    :src="headerData?.user_photo"
                    style="max-height: 48px"
                    alt="avatar"
                />
              </picture>
            </div>
            <div class="user-heder__text user-text-top">
              <div class="user-heder__name">
                {{ headerData?.contact_name }}
              </div>
              <div class="user-heder__role">
                {{ headerData?.title }}
              </div>
            </div>
          </router-link>
          <div class="spollers__item header__notifications notifications-header">
            <button type="button" data-spoller data-spoller-close class="spollers__title notifications-header__title"
                    @click="openNotifications">
              <img src="@/assets/header/header_bell.svg" alt="notifications" v-if="!this.enableNotifications()">
              <img src="@/assets/header/header_bell_red.svg" alt="notifications" v-if="this.enableNotifications()">
            </button>
            <HeaderNotifications
                v-if="visibleNotifications"
                :openNotifications="openNotifications"
                :openChat="openChat"
            />
          </div>

          <div class="spollers__item header__chat chat-header">
            <button
                type="button"
                data-spoller
                data-spoller-close
                :class="`spollers__title chat-header__title ${
                $store.getters.newMessagesCount ? 'newMessage' : ''
              }`"
                @click="openChat"
            >
              <img src="@/assets/header/header_chat.svg" alt="chat"/>
            </button>
            <HeaderChat
                v-show="visibleChat"
                :openNotifications="openNotifications"
                :openChat="openChat"
            />
          </div>
        </div>
        <router-link to="/" class="menu__link link-menu top-header__logo">
          <div class="top-header__logo">
            <img src="@/assets/logo-new.svg" alt="logo" style="width: 65%"/>
          </div>
        </router-link>
        <a
            href="#"
            data-da=".left-header, 767, 3"
            class="header__manager manager-header header__manager-top"
        >
          <div
              style="display: flex; align-items: center"
              @click="openManagerInfo"
          >
            <div class="manager-header__name manager-name_top">
              {{ headerData?.pm_name }} {{ headerData?.pm_last_name }}
            </div>
            <div class="manager-header__avatar">
              <picture
              >
                <source :srcset="headerData?.pm_photo" type="image/webp"/>
                <img
                    :src="headerData?.user_photo"
                    style="border-radius: 100%"
                    alt="manager"
                /></picture>
            </div>
          </div>
          <div
              class="manager-header__info"
              v-if="
              (visibleManagerInfo && headerData.pm_email) ||
              (visibleManagerInfo && headerData.pm_phone)
            "
          >
            <div class="manager-header__info-header">
              <h3>Контакты менеджера</h3>
              <img
                  @click="closeManagerInfo"
                  src="@/assets/icons/close.svg"
                  alt="close"
                  style="width: 14px"
              />
            </div>
            <div class="manager-header__info-body">
              <div class="manager-header__info-item">
                <p v-if="headerData.pm_email">
                  <img
                      class="manager-header_pm-icon"
                      src="@/assets/pm_mail.png"
                      alt="mail"
                  />
                  <a :href="`mailto:${headerData.pm_email}`">{{
                      headerData.pm_email
                    }}</a>
                </p>
                <!-- <img src="@/assets/copy-img.png" alt="copy" @click="copyData(headerData.pm_email)"> -->
              </div>
              <div class="manager-header__info-item">
                <p v-if="headerData.pm_phone">
                  <img
                      class="manager-header_pm-icon"
                      src="@/assets/pm_phone.png"
                      alt="phone"
                  />
                  <a :href="`tel:${headerData.pm_phone}`">{{
                      headerData.pm_phone
                    }}</a>
                </p>
                <!-- <img src="@/assets/copy-img.png" alt="copy" @click="copyData(headerData.pm_phone)"> -->
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </header>
  <div
      class="manager-header__info-bg"
      @click="closeManagerInfo"
      v-if="visibleManagerInfo"
  ></div>
  <div
      class="manager-header__info-bg"
      @click="closeChat"
      v-show="visibleChat"
  ></div>
</template>

<script>
import HeaderNotifications from '@/components/HeaderNotifications.vue';
import HeaderChat from '@/components/HeaderChat.vue';
import axios from 'axios';
import Clipboard from 'clipboard';
import {getHeader, getNotifications, getRaitingApi} from '@/api/get';
import store from "@/store";

export default {
  components: {
    HeaderNotifications,
    HeaderChat,
  },
  data() {
    return {
      visibleNotifications: false,
      visibleChat: false,
      visibleHeaderLeft: false,
      visibleManagerInfo: false,
      pollInterval: null,
      raitingActive: null,
      raitingItems: [
        {value: 1, isActiveRaiting: false},
        {value: 2, isActiveRaiting: false},
        {value: 3, isActiveRaiting: false},
        {value: 4, isActiveRaiting: false},
        {value: 5, isActiveRaiting: false},
      ],
      raitingOurCompany: null,
    };
  },
  methods: {
    enableNotifications() {
      return this.$store.state.enableNotification;
    },
    openNotifications() {
      this.visibleNotifications = !this.visibleNotifications;
      this.visibleChat = false;
    },
    openChat() {
      this.visibleChat = !this.visibleChat;
      this.visibleNotifications = false;
      this.$store.state._newMessagesCount = 0;
    },
    closeChat() {
      this.visibleChat = false;
    },
    openHeaderLeft() {
      const apt = document.querySelector('.header__container');
      if (this.visibleHeaderLeft == false) {
        this.visibleHeaderLeft = true;
        apt.classList.add('menu-open');
      } else {
        this.visibleHeaderLeft = false;
        apt.classList.remove('menu-open');
      }
    },
    openManagerInfo() {
      this.visibleManagerInfo = !this.visibleManagerInfo;
    },
    closeManagerInfo() {
      this.visibleManagerInfo = false;
    },
    copyData(data) {
      const clipboard = new Clipboard('.copy-btn', {
        text: () => data,
      });
      clipboard.on('success', () => {
        alert('Текст скопирован!');
      });
      clipboard.on('error', () => {
        alert('Не удалось скопировать текст!');
      });
      clipboard.onClick(event);
    },
    //меняем isActiveRaiting на true или false у item на который был клик и у всех предыдущих
    async raitingChange(index) {
      let lastActionTime = localStorage.getItem('lastActionTime');
      const hourInMs = 60 * 60 * 1000;
      if (!lastActionTime || Date.now() - lastActionTime > hourInMs) {
        // for (let i = 0; i < this.raitingItems.length; i++) {
        //     this.raitingItems[i].isActiveRaiting = i <= index;
        //     this.raitingActive = index + 1;
        // }
        // localStorage.setItem('raitingActive', this.raitingActive);

        let token = localStorage.getItem('token');
        this.raitingActive = index + 1;
        let url = process.env.VUE_APP_URL;
        let options = {
          mark: this.raitingActive,
        };
        await axios
            .post(url + 'users/set_mark', options, {
              headers: {'Authorization-Token': token},
            })
            .then((response) => {
              this.getRaiting();
              localStorage.setItem('lastActionTime', Date.now());
              // console.log(response);
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    createdRaitingChange() {
      let lastActionTime = localStorage.getItem('lastActionTime');
      const hourInMs = 60 * 60 * 1000;
      // if (!lastActionTime || (Date.now() - lastActionTime) < hourInMs) {
      for (let i = 0; i < this.raitingItems.length; i++) {
        this.raitingItems[i].isActiveRaiting = i < this.raitingActive;
      }
      // }
    },
    getRaiting() {
      getRaitingApi(localStorage.getItem('token')).then((res) => {
        this.raitingOurCompany = res;
        this.raitingActive = Math.floor(this.raitingOurCompany);
        for (let i = 0; i < this.raitingItems.length; i++) {
          this.raitingItems[i].isActiveRaiting = i < this.raitingActive;
        }
      });
    },
    //если raitingActive пустой, то меняем у item isActiveRaiting на true или false при наведении
    hoverRaiting(index) {
      let lastActionTime = localStorage.getItem('lastActionTime');
      const hourInMs = 60 * 60 * 1000;
      if (!lastActionTime || Date.now() - lastActionTime > hourInMs) {
        for (let i = 0; i <= index; i++) {
          this.raitingItems[i].isActiveRaiting = true;
        }
        for (let i = index + 1; i < this.raitingItems.length; i++) {
          this.raitingItems[i].isActiveRaiting = false;
        }
      }
    },
    leaveRaiting() {
      let lastActionTime = localStorage.getItem('lastActionTime');
      const hourInMs = 60 * 60 * 1000;
      if (!lastActionTime || Date.now() - lastActionTime > hourInMs) {
        for (let i = 0; i < this.raitingItems.length; i++) {
          this.raitingItems[i].isActiveRaiting = i < this.raitingActive;
        }
      }
    },
    Notifications(){
      getNotifications(localStorage.getItem('token'))
      // check if the status is completed, if not fetch data every 10minutes.
      this.pollInterval = setInterval(()=>{getNotifications(localStorage.getItem('token'))}, 120000) //save reference to the interval
      setTimeout(() => {
          clearInterval(this.pollInterval)
        }, 36000000) //stop polling after an hour
      console.log(localStorage.getItem('notifications'))
    },

  },
  mounted() {
    getHeader(localStorage.getItem('token'));
    this.getRaiting();
  },
  created() {
    this.createdRaitingChange();
    this.Notifications();
  },
  computed: {
    headerData() {
      return this.$store.getters.headerData;
    },
  },
};
</script>

<style>
.router-link-exact-active.menu__link.link-menu {
  background-color: #f6f6f6;
  -webkit-box-shadow: 0px 0.0625rem 0.125rem rgb(0 0 0 / 6%),
  0px 0.0625rem 0.1875rem rgb(0 0 0 / 10%);
  box-shadow: 0px 0.0625rem 0.125rem rgb(0 0 0 / 6%),
  0px 0.0625rem 0.1875rem rgb(0 0 0 / 10%);
  color: #ba181b;
}

.router-link-active.router-link-exact-active.menu__link.link-menu.top-header__logo {
  box-shadow: none;
  text-align: center;
}

.manager-header__info {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  top: 72px;
  right: 25px;
  z-index: 1000;
}

.manager-header__info-header {
  display: flex;
  justify-content: space-between;
  align-items: unset;
  margin-bottom: 10px;
}

.manager-header__info-header h3 {
  color: #494949;
  font-weight: 600;
  font-size: 16px;
  margin-right: 20px;
}

.manager-header__info-body p strong {
  color: #494949;
}

.manager-header__info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.manager-header__info-item img {
  width: 20px;
}

.manager-header__info-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.rating__item-text {
  position: relative;
}

.rating__item {
  position: absolute;
  left: 2px;
}

.rating__body {
  width: 100%;
}

.rating__item-text.rating__item--active {
  color: #ffd300;
}

.rating__item-text {
  color: #ccc;
  font-size: 26px;
}

.manager-header_pm-icon {
  width: 30px !important;
  margin-right: 5px;
}

.manager-header__info-item p {
  display: flex;
  align-items: center;
}

.newMessage::after {
  width: 8px;
  height: 8px;
  background-color: red;
  position: absolute;
  bottom: 10px;
  content: '';
  border-radius: 4px;
  right: 17px;
}

.chat-header {
  position: relative;
}
</style>
