<template>
  <div id="orderpopup" aria-hidden="true" class="popup orderpopup">
    <div class="popup__wrapper orderpopup__wrapper">
      <div
        class="popup__content orderpopup__content"
        :class="{ 'orderpopup__content-mini': modalContentMini }"
      >
        <button
          data-close
          type="button"
          class="popup__close orderpopup__close"
          @click="openOrderCreate"
        >
          <img src="@/assets/icons/close.svg" alt="close" />
        </button>
        <form
          class="popup__body orderpopup__body body-orderpopup __container"
          @submit="newOrder"
        >
          <p
            style="text-align: center; font-size: 20px"
            v-if="visibleCreateMessage"
          >
            {{ textCreateMessage }}
          </p>
          <div class="body-orderpopup__body" v-if="!visibleCreateMessage">
            <div class="body-orderpopup__form form-orderpopup">
              <div class="body-orderpopup__head">
                <h2 class="body-orderpopup__title">Новая заявка</h2>
              </div>
              <div class="body-orderpopup__forms">
                <div class="form-orderpopup__cargo cargo-orderpopup">
                  <div class="cargo-orderpopup__title">Груз</div>
                  <div class="cargo-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">Откуда</div>
                    <input
                      type="text"
                      class="line-orderpopup__input"
                      @focus="changeVisibleRouteFromCities(0)"
                      @blur="changeVisibleRouteFromCities(150)"
                      @input="cargoRouteFrom(cargo_route_from)"
                      v-model="cargo_route_from"
                      required
                    />
                    <div
                      class="company-modal-list"
                      v-show="visibleRouteFromCities"
                    >
                      <div
                        class="company-modal_item"
                        v-for="city in routeFromCities"
                        :key="city.FullName"
                        @click="cargo_route_from = city.FullName"
                      >
                        <p>{{ city.FullName }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="cargo-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">Куда</div>
                    <input
                      type="text"
                      class="line-orderpopup__input"
                      @focus="changeVisibleRouteToCities(0)"
                      @blur="changeVisibleRouteToCities(150)"
                      @input="cargoRouteTo(cargo_route_to)"
                      v-model="cargo_route_to"
                      required
                    />
                    <div
                      class="company-modal-list"
                      v-show="visibleRouteToCities"
                    >
                      <div
                        class="company-modal_item"
                        v-for="city in routeToCities"
                        :key="city.FullName"
                        @click="cargo_route_to = city.FullName"
                      >
                        <p>{{ city.FullName }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="cargo-orderpopup__line line-orderpopup">
                    <div class="line-profile__item" style="width: 100%">
                      <div class="line-orderpopup__subtitle">
                        Предварительная дата отправки
                      </div>
                      <select
                        class="line-orderpopup__select"
                        style="color: #000"
                        v-model="preliminaryDateDispatchSelect"
                      >
                        <option
                          v-for="option in preliminaryDateDispatch"
                          :key="option"
                          :value="option.id"
                          :hidden="option.hidden"
                          :disabled="option.disabled"
                        >
                          {{ option.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="cargo-orderpopup__line line-orderpopup">
                                        <div class="line-profile__item" style="width: 100%;">
                                            <div class="line-orderpopup__subtitle">Предварительный cпособ погрузки</div>
                                            <select class="line-orderpopup__select" style="color: #000;" v-model="optionSelect">
                                                <option v-for="option in optionValues" :key="option" :value="option.id" :hidden="option.hidden" :disabled="option.disabled">{{ option.title }}</option>
                                            </select>
                                        </div>
                                    </div> -->
                  <div
                    class="cargo-orderpopup__line line-orderpopup line-orderpopup_line"
                  >
                    <div class="line-orderpopup__item">
                      <div class="line-orderpopup__subtitle">
                        Габариты ДхШхВ (м)
                      </div>
                      <input
                        type="text"
                        class="line-orderpopup__input"
                        v-model="cargo_dimensions"
                      />
                    </div>
                    <div class="line-orderpopup__item">
                      <div class="line-orderpopup__subtitle">Вес (кг)</div>
                      <input
                        type="number"
                        class="line-orderpopup__input"
                        v-model="cargo_weight"
                        required
                      />
                    </div>
                    <div class="line-orderpopup__item">
                      <div class="line-orderpopup__subtitle">Объем (м²)</div>
                      <input
                        type="number"
                        class="line-orderpopup__input"
                        v-model="cargo_volume"
                      />
                    </div>
                  </div>
                  <div class="cargo-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      Наименование груза
                    </div>
                    <input
                      type="text"
                      class="line-orderpopup__input"
                      v-model="cargo_name"
                      required
                    />
                  </div>
                  <div class="cargo-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      Объявленная ценность перевозимого груза (стоимость груза),
                      руб.
                    </div>
                    <input
                      type="number"
                      class="line-orderpopup__input"
                      v-model="cargo_cost"
                      required
                    />
                  </div>
                  <!-- <div class="cargo-orderpopup__line line-orderpopup">
                                        <div class="line-orderpopup__subtitle">Новый груз Б/У</div>
                                        <select class="line-orderpopup__select">
                                            <option value="Новый">Новый</option>
                                            <option value="Б/У">Б/У</option>
                                        </select>
                                    </div> -->
                  <!-- <div class="cargo-orderpopup__line line-orderpopup">
                                        <div class="line-orderpopup__subtitle">Вид погрузки/выгрузки</div>
                                        <select class="line-orderpopup__select">
                                            <option value="погрузки">погрузки</option>
                                            <option value="выгрузки">выгрузки</option>
                                        </select>
                                    </div> -->
                  <div class="line-orderpopup__subtitle" style="margin-bottom: 5px">Документы для рейса (?)
                    <span class="tooltiptext">Документы для рейса</span>
                  </div>
                  <div
                    class="cargo-orderpopup__line line-orderpopup cargofile-orderpopup"
                  >
                    <input
                      type="file"
                      id="cargofile-orderpopup__file"
                      class="cargofile-orderpopup__file"
                      @change="onFileChange"
                    />
                    <label
                      for="cargofile-orderpopup__file"
                      class="cargofile-orderpopup__filelabel"
                    >
                      <img
                        src="@/assets/orders/order_upload.svg"
                        alt="upload"
                      />
                      Загрузить документы
                    </label>
                    <div
                      class="cargofile-orderpopup__preview preview-cargofile"
                    >
                      <OrderCreateModalDocument
                        v-for="(document, index) in orderCreateModalDocuments"
                        :key="document"
                        :document="document"
                        :index="index"
                      />
                    </div>
                  </div>
                  <button
                    style="padding-left: 0; display: flex; align-items: center; color: black"
                    class="details-orderpopup__reset"
                    @click.prevent="changeVisibleAdditionalFields"
                  >
                    Дополнительная информация (по желанию)
                    <img
                      src="@/assets/icons/arrow.png"
                      style="width: 23px; margin-left: 5px"
                      :style="{
                        transform: visibleAdditionalFields
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }"
                      alt=""
                    />
                  </button>
                </div>
                <div
                  class="form-orderpopup__sender sender-orderpopup"
                  v-if="visibleAdditionalFields"
                >
                  <div class="cargo-orderpopup__line line-orderpopup">
                    <div class="sender-orderpopup__title">Грузоотправитель</div>
                  </div>
                  <div class="sender-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">Адрес погрузки</div>
                    <div
                      v-for="(adress, index) in loading_address"
                      :key="index"
                      style="width: 100%"
                    >
                      <input
                        type="text"
                        class="line-orderpopup__input"
                        :value="adress"
                        @input="loading_address[index] = $event.target.value"
                      />
                    </div>
                    <button
                      class="sender-orderpopup__line-btn-add"
                      @click.prevent="addAdressPole('loading_address')"
                    >
                      Добавить адрес
                    </button>
                  </div>
                  <div class="cargo-orderpopup__line line-orderpopup">
                    <div class="line-profile__item" style="width: 100%">
                      <div class="line-orderpopup__subtitle">
                        Cпособ погрузки
                      </div>
                      <select
                        class="line-orderpopup__select"
                        style="color: #000"
                        v-model="loadingMethodSelect"
                      >
                        <option
                          v-for="option in loadingMethod"
                          :key="option"
                          :value="option.id"
                          :hidden="option.hidden"
                          :disabled="option.disabled"
                        >
                          {{ option.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="sender-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      Дата и время погрузки
                    </div>
                    <!-- <img class="line-orderpopup__dateicon" src="@/assets/orders/order_datepicker.svg" alt="datetime"> -->
                    <input
                      type="datetime-local"
                      style="width: fit-content"
                      class="line-orderpopup__input line-orderpopup__input_date"
                      v-model="loading_date_time"
                      min="2010-01-01 00:00:00"
                      :max="maxDate"
                    />
                  </div>
                  <div class="sender-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      Название компании Грузоотправителя
                    </div>
                    <input
                      type="text"
                      @focus="
                        changeVisibleCompany('companyModalShipper', true, 0)
                      "
                      @blur="
                        changeVisibleCompany('companyModalShipper', false, 150)
                      "
                      class="line-orderpopup__input"
                      v-model="shipper.company_title"
                    />
                    <div
                      class="company-modal-list"
                      v-show="visibles.companyModalShipper"
                    >
                      <div
                        class="company-modal_item"
                        v-for="company in companiesShipper"
                        :key="company.title"
                        @click="
                          autoCompleteCompany(
                            'focusCompanyShipper',
                            'shipper',
                            company
                          )
                        "
                      >
                        <p>{{ company.title }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="sender-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      ИНН Грузоотправителя
                    </div>
                    <input
                      type="number"
                      minlength="12"
                      maxlength="12"
                      class="line-orderpopup__input"
                      v-model="shipper.TIN"
                    />
                  </div>
                  <div class="sender-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      ФИО Грузоотправителя
                    </div>
                    <input
                      type="text"
                      class="line-orderpopup__input"
                      style="text-transform: capitalize"
                      @focus="
                        changeVisibleCompany(
                          'companyModalShipperContacts',
                          true,
                          0
                        )
                      "
                      @blur="
                        changeVisibleCompany(
                          'companyModalShipperContacts',
                          false,
                          150
                        )
                      "
                      v-model="shipperContact.contact_name"
                    />
                    <div
                      class="company-modal-list"
                      v-if="visibles.companyModalShipperContacts"
                    >
                      <div
                        class="company-modal_item"
                        v-for="contact in focusCompanyShipper?.contacts"
                        :key="contact.title"
                        @click="autoCompleteContact('shipperContact', contact)"
                      >
                        <p>{{ contact.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="sender-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      Номер телефона грузоотправителя
                    </div>
                    <input
                      type="text"
                      class="line-orderpopup__input"
                      v-model="shipperContact.contact_phone"
                    />
                  </div>
                  <div class="sender-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      E-mail грузоотправителя
                    </div>
                    <input
                      type="text"
                      class="line-orderpopup__input"
                      v-model="shipperContact.contact_email"
                    />
                    <div class="line-orderpopup__text">
                      Просьба указать Емайл, чтобы мы могли сразу отправить
                      Транспортные накладные на погрузку
                    </div>
                  </div>
                </div>
                <div
                  class="form-orderpopup__recipient recipient-orderpopup"
                  v-if="visibleAdditionalFields"
                >
                  <div class="recipient-orderpopup__title">Грузополучатель</div>
                  <div class="recipient-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">Адрес выгрузки</div>
                    <div
                      v-for="(adress, index) in unloading_address"
                      :key="index"
                      style="width: 100%"
                    >
                      <input
                        type="text"
                        class="line-orderpopup__input"
                        :value="adress"
                        @input="unloading_address[index] = $event.target.value"
                      />
                    </div>
                    <button
                      class="sender-orderpopup__line-btn-add"
                      @click.prevent="addAdressPole('unloading_address')"
                    >
                      Добавить адрес
                    </button>
                  </div>
                  <div class="cargo-orderpopup__line line-orderpopup">
                    <div class="line-profile__item" style="width: 100%">
                      <div class="line-orderpopup__subtitle">
                        Cпособ выгрузки
                      </div>
                      <select
                        class="line-orderpopup__select"
                        style="color: #000"
                        v-model="unloadingMethodSelect"
                      >
                        <option
                          v-for="option in unloadingMethod"
                          :key="option"
                          :value="option.id"
                          :hidden="option.hidden"
                          :disabled="option.disabled"
                        >
                          {{ option.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="recipient-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">Дата и время выгрузки</div>
                    <img class="line-orderpopup__dateicon" src="@/assets/orders/order_datepicker.svg" alt="datetime">
                    <input type="datetime-local" style="width: fit-content;"
                      class="line-orderpopup__input line-orderpopup__input_date" v-model="unloading_date_time"
                      min='2010-01-01 00:00:00' :max="maxDate">
                  </div> -->
                  <div class="recipient-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      Название компании Грузополучателя
                    </div>
                    <input
                      type="text"
                      @focus="
                        changeVisibleCompany('companyModalConsignee', true, 0)
                      "
                      @blur="
                        changeVisibleCompany(
                          'companyModalConsignee',
                          false,
                          150
                        )
                      "
                      class="line-orderpopup__input"
                      v-model="consignee.company_title"
                    />
                    <div
                      class="company-modal-list"
                      v-show="visibles.companyModalConsignee"
                    >
                      <div
                        class="company-modal_item"
                        v-for="company in companiesConsignee"
                        :key="company.title"
                        @click="
                          autoCompleteCompany(
                            'focusCompanyConsignee',
                            'consignee',
                            company
                          )
                        "
                      >
                        <p>{{ company.title }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="recipient-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      ИНН Грузополучателя
                    </div>
                    <input
                      type="number"
                      class="line-orderpopup__input"
                      v-model="consignee.TIN"
                    />
                  </div>
                  <div class="recipient-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      ФИО Грузополучателя
                    </div>
                    <input
                      type="text"
                      style="text-transform: capitalize"
                      class="line-orderpopup__input"
                      @focus="
                        changeVisibleCompany(
                          'companyModalConsigneeContacts',
                          true,
                          0
                        )
                      "
                      @blur="
                        changeVisibleCompany(
                          'companyModalConsigneeContacts',
                          false,
                          150
                        )
                      "
                      v-model="consigneeContact.contact_name"
                    />
                    <div
                      class="company-modal-list"
                      v-if="visibles.companyModalConsigneeContacts"
                    >
                      <div
                        class="company-modal_item"
                        v-for="contact in focusCompanyConsignee?.contacts"
                        :key="contact.title"
                        @click="
                          autoCompleteContact('consigneeContact', contact)
                        "
                      >
                        <p>{{ contact.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="recipient-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      Номер телефона Грузополучателя
                    </div>
                    <input
                      type="text"
                      class="line-orderpopup__input"
                      v-model="consigneeContact.contact_phone"
                    />
                  </div>
                  <div class="recipient-orderpopup__line line-orderpopup">
                    <div class="line-orderpopup__subtitle">
                      E-mail Грузополучателя
                    </div>
                    <input
                      type="text"
                      class="line-orderpopup__input"
                      v-model="consigneeContact.contact_email"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="body-orderpopup__details details-orderpopup">
              <div
                class="details-orderpopup__item price-orderpopup"
                v-if="1 == 3"
              >
                <div class="price-orderpopup__line price-orderpopup__line_big">
                  <div class="price-orderpopup__text">Стоимость</div>
                  <div class="price-orderpopup__number"></div>
                </div>
                <div class="price-orderpopup__radio">
                  <div class="price-orderpopup__line">
                    <div class="price-orderpopup__line-left">
                      <input
                        type="radio"
                        class="e-danger"
                        name="opportunity_type"
                        id="opportunity_type23"
                        value="with_nds_one_track"
                        v-model="selectedOpportunityField"
                      />
                      <label for="opportunity_type">Отдельное авто с НДС</label>
                    </div>
                  </div>
                  <div class="price-orderpopup__line">
                    <div class="price-orderpopup__line-left">
                      <input
                        type="radio"
                        class="e-danger"
                        name="opportunity_type"
                        id="opportunity_type"
                        value="with_nds_reloading"
                        v-model="selectedOpportunityField"
                      />
                      <label for="opportunity_type">Догруз с НДС</label>
                    </div>
                  </div>
                  <div class="price-orderpopup__line">
                    <div class="price-orderpopup__line-left">
                      <input
                        type="radio"
                        class="e-danger"
                        name="opportunity_type"
                        id="opportunity_type"
                        value="without_nds_one_track"
                        v-model="selectedOpportunityField"
                      />
                      <label for="opportunity_type"
                        >Отдельное авто без НДС</label
                      >
                    </div>
                  </div>
                  <div class="price-orderpopup__line">
                    <div class="price-orderpopup__line-left">
                      <input
                        class="e-danger"
                        type="radio"
                        name="opportunity_type"
                        id="opportunity_type"
                        value="without_nds_reloading"
                        v-model="selectedOpportunityField"
                      />
                      <label for="opportunity_type">Догруз без НДС</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="details-orderpopup__buttons">
                <button
                  type="submit"
                  class="details-orderpopup__redbtn"
                  style="font-size: 16px"
                >
                  Получить расчет стоимости
                </button>
                <!-- <button type="reset" class="details-orderpopup__reset" @click="openOrderCreate">Отменить</button> -->
              </div>
              <!-- <button type="submit" class="details-orderpopup__reset" @click="fullAllPole">Заполнить все поля
                (тест)</button> -->
              <LoadingIndicator v-if="visibleLoading" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import OrderCreateModalDocument from "@/components/order-view/OrderCreateModalDocument.vue";
import LoadingIndicator from "@/components/UI/LoadingIndicator.vue";
import axios from "axios";
import { mask } from "vue-the-mask";
import { getLastDeals, getDetailCompanies, getCities } from "@/api/get";
import { debounce } from "lodash";

export default {
  props: ["openOrderCreate"],
  components: {
    OrderCreateModalDocument,
    LoadingIndicator,
  },
  directives: { mask },
  data() {
    return {
      cargo_route_from: null,
      cargo_route_to: null,
      cargo_name: null,
      cargo_cost: null,
      cargo_dimensions: null,
      cargo_weight: null,
      cargo_volume: null,
      loading_address: [null],
      loading_date_time: null,
      unloading_address: [null],
      unloading_date_time: null,
      shipper: {
        company_title: null,
        TIN: null,
        full_name: null,
        phone: null,
        mail: null,
      },
      consignee: {
        company_title: null,
        TIN: null,
        full_name: null,
        phone: null,
        mail: null,
      },
      consigneeContact: {
        contact_name: null,
        contact_email: null,
        contact_phone: null,
      },
      shipperContact: {
        contact_name: null,
        contact_email: null,
        contact_phone: null,
      },
      visibles: {
        companyModalConsignee: false,
        companyModalConsigneeContacts: false,
        companyModalShipper: false,
        companyModalShipperContacts: false,
      },
      visibleCompanyModalShipper: false,
      visibleCompanyModalConsignee: false,
      visibleCompanyModalConsigneeContacts: false,
      companiesConsignee: [
        {
          title: "Первая компания",
          INN: "1111111111111",
          contact_name: "Тест Тестович Один",
          contact_phone: "890616788",
          contact_email: "test@mail.ru",
        },
        {
          title: "Вторая компания",
          INN: "2222222222222",
          contact_name: "Тест Тестович Два",
          contact_phone: "8906167883873",
          contact_email: "test2@mail.ru",
        },
      ],
      companiesShipper: [
        {
          title: "Первая компания",
          INN: "1111111111111",
          contact_name: "Тест Тестович Один",
          contact_phone: "890616788",
          contact_email: "test@mail.ru",
        },
        {
          title: "Вторая компания",
          INN: "2222222222222",
          contact_name: "Тест Тестович Два",
          contact_phone: "8906167883873",
          contact_email: "test2@mail.ru",
        },
      ],
      focusCompanyShipper: null,
      focusCompanyConsignee: null,
      visibleCreateMessage: false,
      visibleLoading: false,
      textCreateMessage: null,
      modalContentMini: false,
      optionValues: [
        {
          title: "Выберите предварительный способ погрузки",
          id: null,
          disabled: true,
          hidden: true,
        },
        { title: "Не уточнил", id: 3294, disabled: false, hidden: false },
        { title: "Верхний", id: 3295, disabled: false, hidden: false },
        { title: "Боковой", id: 3296, disabled: false, hidden: false },
        { title: "Задний", id: 3297, disabled: false, hidden: false },
        { title: "Аппарели", id: 3298, disabled: false, hidden: false },
        { title: "Верх+Бок", id: 3299, disabled: false, hidden: false },
        { title: "Бок+Зад", id: 3300, disabled: false, hidden: false },
        { title: "Верх+Зад", id: 3301, disabled: false, hidden: false },
        { title: "Верх+Бок+Зад", id: 3302, disabled: false, hidden: false },
        { title: "Гидроборт", id: 3369, disabled: false, hidden: false },
      ],
      optionSelect: null,
      preliminaryDateDispatch: [
        { title: "", id: null, disabled: true, hidden: true },
        { title: "Не выбрано", id: null, disabled: false, hidden: true },
        { title: "Сегодня/завтра", id: 5455, disabled: false, hidden: false },
        { title: "Груз готов", id: 5459, disabled: false, hidden: false },
        { title: "Без срока", id: 5458, disabled: false, hidden: false },
        { title: "В течение недели", id: 5456, disabled: false, hidden: false },
        { title: "В течение месяца", id: 5457, disabled: false, hidden: false },
      ],
      preliminaryDateDispatchSelect: null,
      loadingMethod: [
        { title: "", id: null, disabled: true, hidden: true },
        { title: "Не выбрано", id: null, disabled: false, hidden: false },
        { title: "Верхний", id: 484, disabled: false, hidden: false },
        { title: "Боковой", id: 486, disabled: false, hidden: false },
        { title: "Задний", id: 488, disabled: false, hidden: false },
        { title: "Аппарели", id: 2012, disabled: false, hidden: false },
        { title: "Верх+Бок", id: 2013, disabled: false, hidden: false },
        { title: "Бок+Зад", id: 2014, disabled: false, hidden: false },
        { title: "Верх+Зад", id: 2015, disabled: false, hidden: false },
        { title: "Верх+Бок+Зад", id: 3292, disabled: false, hidden: false },
        { title: "Гидроборт", id: 3370, disabled: false, hidden: false },
      ],
      loadingMethodSelect: null,
      unloadingMethod: [
        { title: "", id: null, disabled: true, hidden: true },
        { title: "Не выбрано", id: null, disabled: false, hidden: false },
        { title: "Верхний", id: 3132, disabled: false, hidden: false },
        { title: "Боковой", id: 3133, disabled: false, hidden: false },
        { title: "Задний", id: 3134, disabled: false, hidden: false },
        { title: "Аппарели", id: 3135, disabled: false, hidden: false },
        { title: "Верх+Бок", id: 3136, disabled: false, hidden: false },
        { title: "Бок+Зад", id: 3137, disabled: false, hidden: false },
        { title: "Верх+Зад", id: 3138, disabled: false, hidden: false },
        { title: "Верх+Бок+Зад", id: 3293, disabled: false, hidden: false },
        { title: "Гидроборт", id: 3307, disabled: false, hidden: false },
      ],
      unloadingMethodSelect: null,
      selectedOpportunityField: null,
      visibleAdditionalFields: false,
      visibleRouteFromCities: false,
      visibleRouteToCities: false,
      routeFromCities: [],
      routeToCities: [],
      requestQueue: Promise.resolve(),
      debouncedCargoRouteFrom: null,
      debouncedCargoRouteTo: null,
      // maxDate: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split("T")[0],
    };
  },
  methods: {
    async newOrder(e) {
      e.preventDefault();
      this.visibleLoading = true;
      let token = localStorage.getItem("token");
      let dealData = {
        route_from: this.cargo_route_from,
        route_to: this.cargo_route_to,
        cargo_name: this.cargo_name,
        cargo_cost: this.cargo_cost,
        cargo_dimensions: this.cargo_dimensions,
        cargo_weight: this.cargo_weight,
        cargo_volume: this.cargo_volume,
        documents: this.orderCreateModalDocuments,
        loading_address: this.loading_address,
        loading_date_time: this.loading_date_time,
        unloading_address: this.unloading_address,
        // "unloading_date_time": this.unloading_date_time,
        selected_opportunity_field: this.selectedOpportunityField,
        // "preliminary_loading_method_id": this.optionSelect,
        preliminary_date_dispatch_id: this.preliminaryDateDispatchSelect,
        loading_method_id: this.loadingMethodSelect,
        unloading_method_id: this.unloadingMethodSelect,
        shipper: {
          company_title: this.shipper.company_title,
          TIN: this.shipper.TIN,
          full_name: this.shipperContact.contact_name,
          phone: this.shipperContact.contact_phone,
          mail: this.shipperContact.contact_email,
        },
        consignee: {
          company_title: this.consignee.company_title,
          TIN: this.consignee.TIN,
          full_name: this.consigneeContact.contact_name,
          phone: this.consigneeContact.contact_phone,
          mail: this.consigneeContact.contact_email,
        },
      };
      let url = process.env.VUE_APP_URL;
      await axios
        .post(url + "deal", dealData, {
          headers: { "Authorization-Token": token },
        })
        .then((response) => {
          // console.log(response)
          this.visibleLoading = false;
          this.visibleCreateMessage = true;
          this.textCreateMessage =
            "Ваша заявка сформирована! Ожидайте расчет стоимости";
          this.modalContentMini = true;
          getLastDeals(localStorage.getItem("token"), {
            limit: "all",
            filters: [],
            search: "",
          });
          this.$store.state.orderCreateModalDocuments = [];
        })
        .catch((err) => {
          console.log(err);
          this.visibleLoading = false;
          this.visibleCreateMessage = true;
          this.textCreateMessage =
            "Сделку сформировать не удалось, попробуйте позже.";
          this.modalContentMini = true;
          this.$store.state.orderCreateModalDocuments = [];
        });
    },
    changeVisibleCompany(object, value, interval) {
      setTimeout(() => {
        this.visibles[object] = value;
        // console.log(1);
      }, interval);
    },
    autoCompleteCompany(object, name, company) {
      this[object] = company;
      this[name].company_title = company.title;
      this[name].TIN = company.INN;
      // this.consignee.full_name = company.contact_name
      // this.consignee.phone = company.contact_phone
      // this.consignee.mail = company.contact_email
    },
    autoCompleteContact(name, contact) {
      this[name].contact_name = contact.name;
      this[name].contact_email = contact.email;
      this[name].contact_phone = contact.phone;
      // console.log(name);
      // this.consignee.full_name = company.contact_name
      // this.consignee.phone = company.contact_phone
      // this.consignee.mail = company.contact_email
    },
    onFileChange(event) {
      let selectedFile = event.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        let fileData = {
          name: selectedFile.name,
          data: fileReader.result,
        };
        this.orderCreateModalDocuments.push(fileData);
      };
      event.target.value = null;
    },
    // getOptionValues(val) {
    //     getCities(val)
    // },
    addAdressPole(adress) {
      this[adress].push(null);
    },
    changeVisibleAdditionalFields() {
      this.visibleAdditionalFields = !this.visibleAdditionalFields;
    },
    changeVisibleRouteFromCities(interval) {
      setTimeout(() => {
        this.visibleRouteFromCities = !this.visibleRouteFromCities;
        // console.log(1);
      }, interval);
    },
    changeVisibleRouteToCities(interval) {
      setTimeout(() => {
        this.visibleRouteToCities = !this.visibleRouteToCities;
        // console.log(1);
      }, interval);
    },
    async cargoRouteFrom(val) {
      console.log(val);
      if (val !== null && val !== undefined && val !== "") {
        this.debouncedCargoRouteFrom(val);
      }
    },
    async cargoRouteTo(val) {
      console.log(val);
      if (val !== null && val !== undefined && val !== "") {
        this.debouncedCargoRouteTo(val);
      }
    },
    async cargo_route_from_api_call(val) {
      try {
        const response = await getCities(val);
        console.log(response);
        this.routeFromCities = response.data;
      } catch (error) {
        console.error("Error fetching cities for route from:", error);
      }
    },
    async cargo_route_to_api_call(val) {
      try {
        const response = await getCities(val);
        console.log(response);
        this.routeToCities = response.data;
      } catch (error) {
        console.error("Error fetching cities for route to:", error);
      }
    },
    // fullAllPole(e) {
    //   e.preventDefault()
    //   this.cargo_route_from = 'test1'
    //   this.cargo_route_to = 'test1'
    //   this.cargo_name = 'test1'
    //   this.cargo_cost = 1234
    //   this.cargo_dimensions = 101010
    //   this.cargo_weight = 1234
    //   this.cargo_volume = 1234
    //   this.orderCreateModalDocuments = []
    //   this.loading_address = ["ул. Ленина, д. 6", "второй адрес"]
    //   this.loading_date_time = "2023-06-15"
    //   this.unloading_address = ["ул. Ленина, д. 5", "first адрес"]
    //   this.unloading_date_time = "2023-06-15"

    //   this.shipper.company_title = 'test1'
    //   this.shipper.TIN = 1234
    //   this.shipperContact.contact_name = 'test1'
    //   this.shipperContact.contact_phone = 'test1'
    //   this.shipperContact.contact_email = 'test1'

    //   this.consignee.company_title = 'test1'
    //   this.consignee.TIN = 12343
    //   this.consigneeContact.contact_name = 'test1'
    //   this.consigneeContact.contact_phone = 'test1'
    //   this.consigneeContact.contact_email = 'test1'
    // }
  },
  computed: {
    maxDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      const currentDateTime = `${
        year + 1
      }-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return currentDateTime;
    },
    orderCreateModalDocuments() {
      return this.$store.getters.orderCreateModalDocuments;
    },
  },
  // watch: {
  //   async cargo_route_from(val) {
  //     console.log(val);
  //     if (val !== null && val !== undefined && val !== "") {
  //       this.requestQueue = this.requestQueue.then(() => {
  //         getCities(val).then((response) => {
  //           console.log(response);
  //           this.routeFromCities = response.data;
  //         });
  //       });

  //       await this.requestQueue;
  //     }
  //   },
  //   async cargo_route_to(val) {
  //     console.log(val);
  //     if (val !== null && val !== undefined && val !== "") {
  //       this.requestQueue = this.requestQueue.then(() => {
  //         getCities(val).then((response) => {
  //           console.log(response);
  //           this.routeToCities = response.data;
  //         });
  //       });

  //       await this.requestQueue;
  //     }
  //   },
  // },
  mounted() {
    getDetailCompanies(localStorage.getItem("token")).then((response) => {
      this.companiesConsignee = response.companies.consignee;
      this.companiesShipper = response.companies.shipper;
    });
  },
  created() {
    // Установите время по умолчанию в 9:00
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    this.loading_date_time = `${year}-${month}-${day}T09:00`;

    this.debouncedCargoRouteFrom = debounce(
      this.cargo_route_from_api_call,
      1000
    );

    this.debouncedCargoRouteTo = debounce(this.cargo_route_to_api_call, 1000);
  },
};
</script>

<style>
.body-orderpopup__form.form-orderpopup {
  overflow-y: scroll;
  max-height: calc(100vh - 162px);
  padding-right: 20px;
}

.popup.orderpopup {
  z-index: 1150;
  visibility: visible;
  overflow: auto;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.403);
}

.orderpopup__content {
  transform: scale(1);
  visibility: visible;
}

.company-modal-list {
  position: absolute;
  top: 80px;
  background-color: #fff;
  width: 300px;
  z-index: 5;
}

.e-danger .e-radio:checked + label::after { /* csslint allow: adjoining-classes */
  background-color: #d84315;
  border-color: #d84315;
}
.e-danger .e-radio:checked:focus + label::after, .e-danger .e-radio:checked + label:hover::after { /* csslint allow: adjoining-classes */
  background-color: #ba330a;
  border-color: #ba330a;
}

.e-danger .e-radio:checked + label::before {
  border-color: #d84315;
}

.e-danger .e-radio:checked:focus + label::before, .e-danger .e-radio:checked + label:hover::before {
  border-color: #ba330a;
}

.e-danger .e-radio + label:hover::before {
  border-color: #b1afaf
}

.company-modal_item {
  cursor: pointer;
  padding: 10px;
}

.company-modal_item:hover {
  background-color: rgb(231, 231, 231);
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("@/assets/orders/order_datepicker.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  position: absolute;
  left: 14px;
  cursor: pointer;
}
input:checked+span {
  color: green;
}
.orderpopup__content-mini {
  width: 50%;
}

.line-orderpopup__select {
  font-weight: 500;
  border: 0.0625rem solid #e5e0e0;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  width: 100%;
  background: transparent;
  padding: clamp(0.4375rem, 0.2983695652rem + 0.6956521739vw, 0.9375rem);
  color: #757273;
}
.line-orderpopup__subtitle .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.line-orderpopup__subtitle:hover .tooltiptext {
  visibility: visible;
}
.body-orderpopup__form.form-orderpopup::-webkit-scrollbar {
  width: 7px;
  background-color: #f9f9fd;
}

.body-orderpopup__form.form-orderpopup::-webkit-scrollbar-thumb {
  background-color: #ba181b;
  border-radius: 5px;
}
.price-orderpopup__line-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-orderpopup__line-left input {
  margin-right: 10px;
}

@media (max-width: 1000px) {
  .orderpopup__content-mini {
    width: 70% !important;
  }
}

@media (max-width: 700px) {
  .orderpopup__content-mini {
    width: 80% !important;
  }
}

@media (max-width: 600px) {
  .orderpopup__content-mini {
    width: 90% !important;
    font-size: 16px !important;
    padding-bottom: 20px !important;
  }
}
</style>
