import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {auth: true},
    component: HomeView
  },
  {
    path: '/information',
    name: 'information',
    meta: {auth: true},
    component: function () {
      return import('../views/InformationView.vue')
    }
  },
  {
    path: '/order',
    name: 'order',
    meta: {auth: true},
    component: function () {
      return import('../views/OrderView.vue')
    }
  },
  {
    path: '/documents',
    name: 'documents',
    meta: {auth: true},
    component: function () {
      return import('../views/DocumentsView.vue')
    }
  },
  {
    path: '/tracking',
    name: 'tracking',
    meta: {auth: true},
    component: function () {
      return import('../views/TrackingView.vue')
    }
  },
  {
    path: '/service',
    name: 'service',
    meta: {auth: true},
    component: function () {
      return import('../views/ServiceView.vue')
    }
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {auth: true},
    component: function () {
      return import('../views/ProfileView.vue')
    }
  },
  {
    path: '/recovery_password',
    name: 'recovery_password',
    component: function () {
      return import('../views/RecoveryPasswordView.vue')
    }
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: function () {
      return import('../views/ChangePasswordView.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/LoginView.vue')
    }
  },
  {
    path: '/register',
    name: 'register',
    component: function () {
      return import('../views/RegisterView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = localStorage.getItem('token')
  const requireAuth = to.matched.some(home => home.meta.auth)
  localStorage.setItem('requireAuth', requireAuth)

  if (requireAuth && !currentUser) {
    next('login')
  } else {
    next()
  }
})

export default router
