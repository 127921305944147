<template>
  <section class="page__map map">
    <div class="map__container">
      <div class="map__wrapperr" ref="mapContainer" id="map" style="height: 80%; margin-top: 50px;" v-if="markerFeatures.length"></div>
      <div class="map-none__wrapperr" v-else>
        <div style="position:relative;overflow:hidden;height:100%;"><a
            href="https://yandex.ru/maps/11147/engels/?utm_medium=mapframe&utm_source=maps"
            style="color:#eee;font-size:12px;position:absolute;top:0px;">Энгельс</a><a
            href="https://yandex.ru/maps/11147/engels/?ll=46.051137%2C51.521936&utm_medium=mapframe&utm_source=maps&z=11.89"
            style="color:#eee;font-size:12px;position:absolute;top:14px;">Саратовская область — Яндекс Карты</a><iframe
            src="https://yandex.ru/map-widget/v1/?ll=46.051137%2C51.521936&z=11.89" width="100%" height="100%"
            frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
      </div>
    </div>
  </section>
</template>

<script>
import TrackingMap from '@/components/tracking-view/TrackingMap.vue'
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat, getCenter } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { getLastDeals } from '@/api/get'

export default {
    props: ['openModal', 'deal'],
    components: {TrackingMap},
    data() {
        return {
        lastDeals: this.$store.state.lastDeals,
        markerFeatures: []
        }
    },
    mounted() {
        getLastDeals(localStorage.getItem('token'), { limit: 'all', filters: [], search: '' }).then(response => {
          let id = 1
          for (let i = 0; i < this.$store.state.lastDeals.length; i++) {
            const movizor = this.$store.state.lastDeals[i].movizor;
            if (movizor !== null) {
              this.markerFeatures.push({
                id: id++, // Используем уникальные идентификаторы для каждой метки
                lon: movizor.lon,
                lat: movizor.lat
              });
              console.log(2);
            }
          }
          setTimeout(() => {
            this.initializeMap();
            this.addMarkers();
          }, 500);
        })
    },
    methods: {
        initializeMap() {
        const map = new Map({
            target: this.$refs.mapContainer,
            layers: [
            new TileLayer({
                source: new OSM()
            })
            ],
            view: new View({
            center: fromLonLat([this.markerFeatures != 0 ? this.markerFeatures[0].lon : 0, this.markerFeatures != 0 ? this.markerFeatures[0].lat : 0]), // Значение по умолчанию
            zoom: 12
            })
        });
        this.map = map;

        // if (this.deal !== null && this.deal.movizor) {
        //     const lat = this.markerFeatures[1].lon;
        //     const lat = this.markerFeatures[1].lat;
        //     const center = lon && lat ? fromLonLat([lon, lat]) : fromLonLat([0, 0]);
        //     map.getView().setCenter(center);
        //     // console.log(1);
        // }
        },
        addMarkers() {
        // if (this.deal && this.deal.movizor) {
        //     this.markerFeatures.push({
        //         id: 1,
        //         lon: this.deal.movizor.lon,
        //         lat: this.deal.movizor.lat
        //     });
        // }

        const markerSource = new VectorSource();
        
        if (this.markerFeatures != []) {
          this.markerFeatures.forEach(marker => {
              const { lon, lat, id } = marker;
              const markerPoint = new Point(fromLonLat([lon, lat]));
              const markerFeature = new Feature({
              geometry: markerPoint,
              id: id
              });
              const markerStyle = new Style({
              image: new Icon({
                  src: require('@/assets/marker-icon-2x.png'), // Путь к иконке маркера
                  scale: 0.5
              })
              });
              markerFeature.setStyle(markerStyle);
              markerSource.addFeature(markerFeature);
          });
        }

        const markerLayer = new VectorLayer({
            source: markerSource
        });

        this.map.addLayer(markerLayer);
        }
    }
}
</script>

<style scoped>
/* .map__wrapperr{
  padding: 0px !important;
  margin: 0px !important;
  max-width: 100vw !important;
} */
.map {
  overflow-y: hidden;
}
.map-none__wrapperr {
  margin-top: 75px;
  font-size: 17px;
  height: 80%;
}</style>