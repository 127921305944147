import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vue3Toastify, { toast } from 'vue3-toastify';
import VueClickAway from "vue3-click-away";

createApp(App)
  .use(store)
  .use(router)
  .use(VueClickAway)
  .use(Vue3Toastify, {
    position: toast.POSITION.BOTTOM_RIGHT,
    multiple: false,
    autoClose: false,
  })
  .mount('#app');
