<template>
  <div class="spollers__body notifications-header__body body-notifications" v-click-away="openNotifications">
    <div class="body-notifications__head">
      <div class="body-notifications__text">
        <div class="body-notifications__title">
          Уведомления (<span class="body-notifications__quan">{{ this.getCountNotifications() }}</span>)
        </div>
        <a href="#" class="body-notifications__read" @click="cleanAllNotifications" v-if="this.getCountNotifications() > 0">
          Отметить все как прочитанные
        </a>
      </div>
      <button type="button" class="spollers__title body-notifications__chat" @click="openChat">
        <img src="@/assets/header/header_chat.svg" alt="chat">
      </button>
      <div class="body-notifications__close" @click="openNotifications">
        <img src="@/assets/orders/order_close.svg" alt="close">
      </div>
    </div>
    <div class="body-notifications__body">
      <HeaderNotificationsItem v-for="notification in this.notifications" :index="notification"
                               :notification="notification" />
    </div>
  </div>
</template>

<script>
import HeaderNotificationsItem from '@/components/HeaderNotificationsItem.vue'
import {deleteNotifications} from '@/api/get'
export default {
  props: ['openNotifications', 'openChat'],
  components: {
    HeaderNotificationsItem
  },

  data() {
    return {
      status: null,
      pollInterval: null,
      notifications: this.$store.state.notifications
    }
  },

  methods: {

    getCountNotifications(){
      return this.notifications.length
    },

    cleanAllNotifications(){
      if (this.getCountNotifications() > 0) {
      let ids = this.$store.state.notifications.map(function (o) {
          return o.id
        })
        console.log(ids)
        deleteNotifications(localStorage.getItem("token"), ids)
      }

    },
  },

}


</script>

<style>

</style>