<template>
  <div id="orderpopup" class="popup orderpopup">
    <div class="popup__wrapper orderpopup__wrapper">
      <div
        class="popup__content orderpopup__content"
        :class="[dealItem.is_new ? '' : 'confirm-deal']"
      >
        <button
          data-close
          type="button"
          style="outline: none"
          class="popup__close orderpopup__close"
          @click="visibleChange"
        >
          <img src="@/assets/icons/close.svg" alt="close" />
        </button>
        <form class="popup__body orderpopup__body body-orderpopup __container">
          <div class="body-orderpopup__body">
            <div class="body-orderpopup__form form-orderpopup">
              <div class="body-orderpopup__head">
                <h2 class="body-orderpopup__title">{{ orderDetailTitle }}</h2>
              </div>
              <div class="body-orderpopup__forms">
                <div>
                  <!-- <div data-focusout class="green__border"></div> -->
                  <div class="form-orderpopup__cargo cargo-orderpopup">
                    <div class="cargo-orderpopup__title">Груз</div>
                    <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">Откуда</div>
                      <input
                        type="text"
                        placeholder="Введите Откуда"
                        class="line-orderpopup__input"
                        @focus="changeVisibleRouteFromCities(0)"
                        @blur="changeVisibleRouteFromCities(150)"
                        @input="cargoRouteFrom(dealItem.route_from)"
                        v-model="dealItem.route_from"
                        :readonly="readonlyDealItem"
                        ref="route_from"
                      />
                      <div
                        class="company-modal-list"
                        v-show="visibleRouteFromCities"
                      >
                        <div
                          class="company-modal_item"
                          v-for="city in routeFromCities"
                          :key="city.FullName"
                          @click="cargo_route_from = city.FullName"
                        >
                          <p>{{ city.FullName }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">Куда</div>
                      <input
                        type="text"
                        placeholder="Введите Куда"
                        class="line-orderpopup__input"
                        @focus="changeVisibleRouteToCities(0)"
                        @blur="changeVisibleRouteToCities(150)"
                        @input="cargoRouteTo(dealItem.route_to)"
                        v-model="dealItem.route_to"
                        :readonly="readonlyDealItem"
                        ref="route_to"
                      />
                      <div
                        class="company-modal-list"
                        v-show="visibleRouteToCities"
                      >
                        <div
                          class="company-modal_item"
                          v-for="city in routeToCities"
                          :key="city.FullName"
                          @click="cargo_route_to = city.FullName"
                        >
                          <p>{{ city.FullName }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="line-profile__item" style="width: 100%">
                        <div class="line-orderpopup__subtitle">
                          Предварительная дата отправки
                        </div>
                        <select
                          class="line-orderpopup__select"
                          ref="preliminary_date_dispatch_id"
                          style="color: #000"
                          v-model="dealItem.preliminary_date_dispatch_id"
                          :disabled="readonlyDealItem"
                        >
                          <option
                            v-for="option in preliminaryDateDispatch"
                            :key="option"
                            :value="option.id"
                            :hidden="option.hidden"
                            :disabled="option.disabled"
                          >
                            {{ option.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="line-profile__item" style="width: 100%;">
                        <div class="line-orderpopup__subtitle">Предварительный cпособ погрузки</div>
                        <select class="line-orderpopup__select" ref="preliminary_loading_method_id" style="color: #000;" v-model="dealItem.preliminary_loading_method_id" :disabled="readonlyDealItem">
                          <option v-for="option in optionValues" :key="option" :value="option.id" :hidden="option.hidden" :disabled="option.disabled">{{ option.title }}</option>
                        </select>
                      </div>
                    </div> -->
                    <div
                      class="cargo-orderpopup__line line-orderpopup line-orderpopup_line"
                    >
                      <div class="line-orderpopup__item">
                        <div class="line-orderpopup__subtitle">
                          Габариты ДхШхВ (м)
                        </div>
                        <input
                          type="text"
                          v-model="dealItem.cargo_dimensions"
                          class="line-orderpopup__input"
                          :readonly="readonlyDealItem"
                          ref="cargo_dimensions"
                        />
                      </div>
                      <div class="line-orderpopup__item">
                        <div class="line-orderpopup__subtitle">Вес (кг)</div>
                        <input
                          type="text"
                          class="line-orderpopup__input"
                          v-model="dealItem.cargo_weight"
                          :readonly="readonlyDealItem"
                          ref="cargo_weight"
                        />
                      </div>
                      <div class="line-orderpopup__item">
                        <div class="line-orderpopup__subtitle">Объем (м²)</div>
                        <input
                          type="text"
                          class="line-orderpopup__input"
                          v-model="dealItem.cargo_volume"
                          :readonly="readonlyDealItem"
                          ref="cargo_volume"
                        />
                      </div>
                    </div>
                    <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Наименование груза
                      </div>
                      <input
                        type="text"
                        placeholder="Введите Наименование груза"
                        class="line-orderpopup__input"
                        v-model="dealItem.cargo_name"
                        :readonly="readonlyDealItem"
                        ref="cargo_name"
                      />
                    </div>
                    <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Объявленная ценность перевозимого груза (стоимость
                        груза), руб.
                      </div>
                      <input
                        type="text"
                        placeholder="Введите Объявленная ценность перевозимого груза"
                        class="line-orderpopup__input"
                        v-model="dealItem.cargo_price"
                        :readonly="readonlyDealItem"
                        ref="cargo_price"
                      />
                    </div>
                    <!-- <div class="cargo-orderpopup__line line-orderpopup">
                        <div class="line-orderpopup__subtitle">Новый груз Б/У</div>
                        <select class="line-orderpopup__select">
                            <option value="Новый">Новый</option>
                            <option value="Б/У">Б/У</option>
                        </select>
                    </div> -->
                    <!-- <div class="cargo-orderpopup__line line-orderpopup">
                        <div class="line-orderpopup__subtitle">Вид погрузки/выгрузки</div>
                        <select class="line-orderpopup__select">
                            <option value="погрузки">погрузки</option>
                            <option value="выгрузки">выгрузки</option>
                        </select>
                    </div> -->
                    <!-- <div class="cargo-orderpopup__line line-orderpopup cargofile-orderpopup">
                        <input type="file" multiple id="cargofile-orderpopup__file" class="cargofile-orderpopup__file">
                        <label for="cargofile-orderpopup__file" class="cargofile-orderpopup__filelabel">
                            <img src="@/assets/orders/order_upload.svg" alt="upload">
                            Загрузить документы
                        </label>
                        <div class="cargofile-orderpopup__preview preview-cargofile">
                            <div class="preview-cargofile__line" v-for="document in dealItem.documents" :key="document">
                                <div class="preview-cargofile__icon">
                                    <img src="@/assets/orders/order_document.svg" alt="icon">
                                </div>
                                <div class="preview-cargofile__name">{{ document.name }}</div>
                                <div class="preview-cargofile__links">
                                    <a :href="document.url" class="preview-cargofile__link" target="_blank">
                                        <img src="@/assets/orders/order_download.svg" alt="download">
                                    </a>
                                    <div class="preview-cargofile__link preview-cargofile__link_delete">
                                        <img src="@/assets/orders/order_close.svg" alt="delete">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="line-orderpopup__subtitle" style="margin-bottom: 5px">Документы для рейса (?)
                      <span class="tooltiptext">Документы для рейса</span>
                    </div>
                    <div
                      class="cargo-orderpopup__line line-orderpopup cargofile-orderpopup"
                    >
                      <input
                        v-if="!readonlyDealItem"
                        type="file"
                        id="cargofile-orderpopup__file"
                        class="cargofile-orderpopup__file"
                        @change="onFileChange"
                      />
                      <label
                        v-if="!readonlyDealItem"
                        for="cargofile-orderpopup__file"
                        class="cargofile-orderpopup__filelabel"
                      >
                        <img
                          src="@/assets/orders/order_upload.svg"
                          alt="upload"
                        />
                        Загрузить документы
                      </label>
                      <div
                        class="cargofile-orderpopup__preview preview-cargofile"
                      >
                        <OrderDetailChangeModalDocuments
                          v-for="(document, index) in dealItem.documents"
                          :key="document"
                          :document="document"
                          :index="index"
                          :readonlyDealItem="readonlyDealItem"
                          @deleteDocument="deleteDocument"
                        />
                      </div>
                    </div>
                    <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="sender-orderpopup__title">
                        Грузоотправитель
                      </div>
                    </div>
                  </div>
                  <div class="form-orderpopup__sender sender-orderpopup">
                    <div class="sender-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Адрес погрузки
                      </div>
                      <div
                        v-for="(adress, index) in dealItem.loading_point"
                        :key="index"
                        style="width: 100%"
                      >
                        <input
                          type="text"
                          ref="loading_point"
                          placeholder="Введите Адрес погрузки"
                          class="line-orderpopup__input"
                          :readonly="readonlyDealItem"
                          :value="adress"
                          @input="
                            dealItem.loading_point[index] = $event.target.value
                          "
                        />
                      </div>
                      <button
                        v-if="visibleEditBtn"
                        class="sender-orderpopup__line-btn-add"
                        @click.prevent="addAdressPole('loading_point')"
                      >
                        Добавить адрес
                      </button>
                    </div>
                    <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="line-profile__item" style="width: 100%">
                        <div class="line-orderpopup__subtitle">
                          Способ погрузки
                        </div>
                        <select
                          class="line-orderpopup__select"
                          ref="loading_method_id"
                          style="color: #000"
                          v-model="dealItem.loading_method_id"
                          :disabled="readonlyDealItem"
                        >
                          <option
                            v-for="option in loadingMethod"
                            :key="option"
                            :value="option.id"
                            :hidden="option.hidden"
                            :disabled="option.disabled"
                          >
                            {{ option.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="sender-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Дата и время погрузки
                      </div>
                      <!-- <img
                        class="line-orderpopup__dateicon"
                        src="@/assets/orders/order_datepicker.svg"
                        alt="datetime"
                      /> -->
                      <input
                        type="datetime-local"
                        placeholder="Введите Дату и время погрузки"
                        style="width: fit-content"
                        v-model="dealItem.loading_date"
                        class="line-orderpopup__input line-orderpopup__input_date"
                        :readonly="readonlyDealItem"
                        ref="loading_date"
                        :max="maxDate"
                      />
                    </div>
                    <div class="sender-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Название компании Грузоотправителя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.shipper.title"
                        placeholder="Введите Название компании Грузоотправителя"
                        class="line-orderpopup__input"
                        ref="shipper.title"
                        :readonly="readonlyDealItem"
                      />
                    </div>
                    <div class="sender-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        ИНН Грузоотправителя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.shipper.INN"
                        placeholder="Введите ИНН Грузоотправителя"
                        class="line-orderpopup__input"
                        :readonly="readonlyDealItem"
                        ref="shipper.INN"
                      />
                    </div>
                    <div class="sender-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        ФИО Грузоотправителя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.shipper.name"
                        placeholder="Введите ФИО Грузоотправителя"
                        class="line-orderpopup__input"
                        style="text-transform: capitalize"
                        :readonly="readonlyDealItem"
                        ref="shipper.name"
                      />
                    </div>
                    <div class="sender-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Номер телефона грузоотправителя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.shipper.phone"
                        placeholder="Введите Номер телефона грузоотправителя"
                        class="line-orderpopup__input"
                        :readonly="readonlyDealItem"
                        ref="shipper.phone"
                      />
                    </div>
                    <div class="sender-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        E-mail грузоотправителя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.shipper.email"
                        placeholder="Введите E-mail грузоотправителя"
                        class="line-orderpopup__input"
                        :readonly="readonlyDealItem"
                        ref="shipper.email"
                      />
                      <!-- <div class="line-orderpopup__text">Просьба указать Емайл, чтобы мы могли сразу
                                                отправить Транспортные накладные на погрузку</div> -->
                    </div>
                  </div>
                  <div class="form-orderpopup__recipient recipient-orderpopup">
                    <div class="recipient-orderpopup__title">
                      Грузополучатель
                    </div>
                    <div class="recipient-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Адрес выгрузки
                      </div>
                      <div
                        v-for="(adress, index) in dealItem.unloading_point"
                        :key="index"
                        style="width: 100%"
                      >
                        <input
                          type="text"
                          class="line-orderpopup__input"
                          placeholder="Введите Адрес выгрузки"
                          ref="unloading_point"
                          :readonly="readonlyDealItem"
                          :value="adress"
                          @input="
                            dealItem.unloading_point[index] =
                              $event.target.value
                          "
                        />
                      </div>
                      <button
                        v-if="visibleEditBtn"
                        class="sender-orderpopup__line-btn-add"
                        @click.prevent="addAdressPole('unloading_point')"
                      >
                        Добавить адрес
                      </button>
                    </div>
                    <div class="cargo-orderpopup__line line-orderpopup">
                      <div class="line-profile__item" style="width: 100%">
                        <div class="line-orderpopup__subtitle">
                          Способ выгрузки
                        </div>
                        <select
                          class="line-orderpopup__select"
                          ref="unloading_method_id"
                          style="color: #000"
                          v-model="dealItem.unloading_method_id"
                          :disabled="readonlyDealItem"
                        >
                          <option
                            v-for="option in unloadingMethod"
                            :key="option"
                            :value="option.id"
                            :hidden="option.hidden"
                            :disabled="option.disabled"
                          >
                            {{ option.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="recipient-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Название компании Грузополучателя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.consignee.title"
                        placeholder="Введите Название компании Грузополучателя"
                        class="line-orderpopup__input"
                        :readonly="readonlyDealItem"
                        ref="consignee.title"
                      />
                    </div>
                    <div class="recipient-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        ИНН Грузополучателя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.consignee.INN"
                        placeholder="Введите ИНН Грузополучателя"
                        class="line-orderpopup__input"
                        :readonly="readonlyDealItem"
                        ref="consignee.INN"
                      />
                    </div>
                    <div class="recipient-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        ФИО Грузополучателя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.consignee.name"
                        placeholder="Введите ФИО Грузополучателя"
                        class="line-orderpopup__input"
                        style="text-transform: capitalize"
                        :readonly="readonlyDealItem"
                        ref="consignee.name"
                      />
                    </div>
                    <div class="recipient-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Номер телефона Грузополучателя
                      </div>
                      <input
                        v-model="dealItem.consignee.phone"
                        type="text"
                        placeholder="Введите Номер телефона Грузополучателя"
                        class="line-orderpopup__input"
                        :readonly="readonlyDealItem"
                        ref="consignee.phone"
                      />
                    </div>
                    <div class="recipient-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        E-mail Грузополучателя
                      </div>
                      <input
                        type="text"
                        v-model="dealItem.consignee.email"
                        placeholder="Введите E-mail Грузополучателя"
                        class="line-orderpopup__input"
                        :readonly="readonlyDealItem"
                        ref="consignee.email"
                      />
                    </div>
                    <!-- <div class="recipient-orderpopup__line line-orderpopup">
                      <div class="line-orderpopup__subtitle">
                        Дата и время выгрузки
                      </div>
                      <img
                        class="line-orderpopup__dateicon"
                        src="@/assets/orders/order_datepicker.svg"
                        alt="datetime"
                      />
                      <input
                        type="datetime-local"
                        placeholder="Введите Дату и время выгрузки"
                        v-model="dealItem.unloading_date"
                        class="line-orderpopup__input line-orderpopup__input_date"
                        :readonly="readonlyDealItem"
                        ref="unloading_date"
                        :max="maxDate"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="body-orderpopup__details details-orderpopup">
              <div
                class="details-orderpopup__item price-orderpopup"
                v-if="parseDealOpportunity != 0 && !dealItem.is_new"
              >
                <div class="price-orderpopup__line price-orderpopup__line_big">
                  <div class="price-orderpopup__text">Стоимость</div>
                  <div class="price-orderpopup__number">
                    {{ parseDealOpportunity }} ₽
                  </div>
                </div>
                <div
                  class="price-orderpopup__line"
                  v-for="priceProduct in dealItem.products"
                  :key="priceProduct"
                >
                  <div
                    class="price-orderpopup__text"
                    v-if="dealItem.is_new == false"
                  >
                    {{ priceProduct.title }}
                  </div>
                  <div
                    class="price-orderpopup__number"
                    v-if="dealItem.is_new == false"
                  >
                    {{
                      priceProduct.price
                        .split(".")[0]
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }}
                  </div>
                </div>
              </div>
              <div
                class="details-orderpopup__item price-orderpopup"
                v-if="dealItem.is_new && dealItem?.with_nds_one_track != 0"
              >
                <div class="price-orderpopup__line price-orderpopup__line_big">
                  <div class="price-orderpopup__text">Стоимость с НДС</div>
                  <div
                    class="price-orderpopup__number"
                    v-if="dealItem.is_new == false"
                  >
                    {{ parseDealOpportunity }} ₽
                  </div>
                </div>
                <div
                  class="price-orderpopup__line"
                  v-for="priceProduct in dealItem.products"
                  :key="priceProduct"
                >
                  <div
                    class="price-orderpopup__text"
                    v-if="dealItem.is_new == false"
                  >
                    {{ priceProduct.title }}
                  </div>
                  <div
                    class="price-orderpopup__number"
                    v-if="dealItem.is_new == false"
                  >
                    {{
                      priceProduct.price
                        .split(".")[0]
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }}
                  </div>
                </div>
                <div
                  class="price-orderpopup__radio"
                  v-if="dealItem.from_lk && dealItem.is_new == true"
                >
                  <div class="price-orderpopup__line">
                    <div class="price-orderpopup__line-left">
                      <input
                        type="radio"
                        name="opportunity_type"
                        id="opportunity_type"
                        value="with_nds_one_track"
                        v-model="dealItem.selected_opportunity_field"
                        :disabled="readonlyDealItem"
                      />
                      <label for="opportunity_type">Отдельное авто</label>
                    </div>
                    {{
                      dealItem?.with_nds_one_track != 0
                        ? `${dealItem?.with_nds_one_track
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`
                        : null
                    }}
                  </div>
                  <div class="price-orderpopup__line">
                    <div class="price-orderpopup__line-left">
                      <input
                        type="radio"
                        name="opportunity_type"
                        id="opportunity_type"
                        value="with_nds_reloading"
                        v-model="dealItem.selected_opportunity_field"
                        :disabled="readonlyDealItem"
                      />
                      <label for="opportunity_type">Догруз</label>
                    </div>
                    {{
                      dealItem?.with_nds_reloading != 0
                        ? `${dealItem?.with_nds_reloading
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`
                        : null
                    }}
                  </div>
                </div>
              </div>
              <div
                class="details-orderpopup__item price-orderpopup"
                v-if="dealItem.is_new && dealItem?.without_nds_reloading != 0"
              >
                <div class="price-orderpopup__line price-orderpopup__line_big">
                  <div class="price-orderpopup__text">Стоимость без НДС</div>
                  <div
                    class="price-orderpopup__number"
                    v-if="dealItem.is_new == false"
                  >
                    {{ parseDealOpportunity }} ₽
                  </div>
                </div>
                <div
                  class="price-orderpopup__line"
                  v-for="priceProduct in dealItem.products"
                  :key="priceProduct"
                >
                  <div
                    class="price-orderpopup__text"
                    v-if="dealItem.is_new == false"
                  >
                    {{ priceProduct.title }}
                  </div>
                  <div
                    class="price-orderpopup__number"
                    v-if="dealItem.is_new == false"
                  >
                    {{
                      priceProduct.price
                        .split(".")[0]
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    }}
                  </div>
                </div>
                <div
                  class="price-orderpopup__radio"
                  v-if="dealItem.from_lk && dealItem.is_new == true"
                >
                  <div class="price-orderpopup__line">
                    <div class="price-orderpopup__line-left">
                      <input
                        type="radio"
                        name="opportunity_type"
                        id="opportunity_type"
                        value="without_nds_one_track"
                        v-model="dealItem.selected_opportunity_field"
                        :disabled="readonlyDealItem"
                      />
                      <label for="opportunity_type">Отдельное авто</label>
                    </div>
                    {{
                      dealItem?.without_nds_one_track != 0
                        ? `${dealItem?.without_nds_one_track
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`
                        : null
                    }}
                  </div>
                  <div class="price-orderpopup__line">
                    <div class="price-orderpopup__line-left">
                      <input
                        type="radio"
                        name="opportunity_type"
                        id="opportunity_type"
                        value="without_nds_reloading"
                        v-model="dealItem.selected_opportunity_field"
                        :disabled="readonlyDealItem"
                      />
                      <label for="opportunity_type">Догруз</label>
                    </div>
                    {{
                      dealItem?.without_nds_reloading != 0
                        ? `${dealItem?.without_nds_reloading
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽`
                        : null
                    }}
                  </div>
                </div>
              </div>
              <div
                class="details-orderpopup__item carrier-orderpopup"
                v-if="dealItem.drivers.length"
              >
                <div class="carrier-orderpopup__title">Водитель</div>
                <div
                  data-spollers
                  class="spollers carrier-orderpopup__spollers spollers-orderpopup"
                >
                  <div
                    class="spollers__item spollers-orderpopup__item"
                    v-for="carrierItem in dealItem.drivers"
                    :key="carrierItem"
                  >
                    <button
                      type="button"
                      data-spoller
                      class="spollers__title spollers-orderpopup__title"
                      @click="openSpollersBody"
                    >
                      {{ carrierItem.name }}
                    </button>
                    <div
                      class="spollers__body spollers-orderpopup__body"
                      v-if="visibleSpollersBody"
                    >
                      <div class="spollers-orderpopup__line">
                        <div class="spollers-orderpopup__gray">
                          Паспортные данные
                        </div>
                        <div class="spollers-orderpopup__black">
                          {{ carrierItem.passport }}
                        </div>
                      </div>
                      <!-- <div class="spollers-orderpopup__line">
                          <div class="spollers-orderpopup__gray">Водительское удостоверение</div>
                          <div class="spollers-orderpopup__black">50 08 000000</div>
                      </div> -->
                      <div class="spollers-orderpopup__line">
                        <div class="spollers-orderpopup__gray">
                          Номер ТС и прицепа
                        </div>
                        <div class="spollers-orderpopup__black">
                          {{ carrierItem.vehicle_nums }}
                        </div>
                      </div>
                      <div class="spollers-orderpopup__line">
                        <div class="spollers-orderpopup__gray">Телефон</div>
                        <div class="spollers-orderpopup__black">
                          {{ carrierItem.phone }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="dealItem.deal_details.length"
                class="details-orderpopup__item files-orderpopup"
                style="max-width: 400px"
              >
                <div class="files-orderpopup__head">
                  <div class="files-orderpopup__title">Документы по рейсу</div>
                  <!-- <div class="files-orderpopup__file" v-if="visibleEditBtn">
                          <input type="file" id="files-orderpopup__file" class="files-orderpopup__input" @change="onFileChange">
                          <label for="files-orderpopup__file" class="files-orderpopup__label">
                              <img src="@/assets/orders/order_upload.svg" alt="upload">
                              Загрузить
                          </label>
                      </div> -->
                </div>
                <div class="files-orderpopup__body">
                  <OrderDetailModalDocument :dealItem="dealItem" />
                </div>
              </div>
              <div
                class="details-orderpopup__buttons"
                v-if="
                  dealItem.is_new == true &&
                  !isObjectEdit &&
                  dealItem.from_lk === true
                "
                style="display: block"
              >
                <p
                  v-if="errorConfirm"
                  style="
                    color: red;
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 20px;
                  "
                >
                  {{ errorConfirm }}
                </p>
                <!-- v-if="dealItem.from_lk === true" добавить!!! -->
                <!-- <button
                  class="details-orderpopup__redbtn"
                  style="width: 100%; margin-bottom: 20px"
                  @click="openEditBtn"
                >
                  Изменить
                </button> -->
                <button v-if="dealItem[dealItem.selected_opportunity_field] != 0"
                  class="details-orderpopup__greenbtn"
                  style="width: 100%"
                  @click.prevent="openConfirmOrder"
                >
                  Подтвердить
                </button>
              </div>
              <div v-if="dealItem.is_new == false">
                <p
                  class="details-orderpopup__greenbtn"
                  style="text-align: center; font-size: 18px; margin-top: 10px"
                >
                  Подтверждено
                </p>
              </div>
              <!-- <p v-if="errorConfirm" style="color:red; text-align:center; margin-bottom:10px; font-size:20px;">{{ errorConfirm }}</p> -->
              <div
                class="details-orderpopup__buttons"
                v-if="isObjectEdit && dealItem.is_new && dealItem.from_lk"
              >
                <button class="details-orderpopup__redbtn" @click="editDeal">
                  Сохранить
                </button>
                <button class="details-orderpopup__reset" @click="cancelEdit">
                  Отменить
                </button>
              </div>
              <LoadingIndicator v-if="visibleLoadingIndicator" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <ConfirmOrderModal
      v-if="visibleConfirmOrder"
      :openConfirmOrder="openConfirmOrder"
      :number_deal="dealItem.number_deal"
      :getDetailDeal="getDetailDeal"
      :dealItem="dealItem"
    />
  </div>
</template>

<script>
import ConfirmOrderModal from "@/components/ConfirmOrderModal.vue";
import OrderDetailModalDocument from "@/components/order-view/OrderDetailModalDocument.vue";
import OrderDetailChangeModalDocuments from "@/components/order-view/OrderDetailChangeModalDocuments.vue";
import LoadingIndicator from "@/components/UI/LoadingIndicator.vue";
import { getDetailCompanies, getCities } from "@/api/get";
import axios from "axios";
import { debounce } from "lodash";

export default {
  components: {
    ConfirmOrderModal,
    OrderDetailModalDocument,
    OrderDetailChangeModalDocuments,
    LoadingIndicator,
  },
  props: [
    "visibleChange",
    "dealItem",
    "getDetailDeal",
    "orderDetailTitle",
    "orderDetailReadonly",
  ],
  data() {
    return {
      isObjectEdit: false,
      visibleSpollersBody: false,
      visibleConfirmOrder: false,
      visibleEditBtn: false,
      detailOrderTitle: "Редактирование заявки",
      dealItemBackup: Object.freeze(JSON.parse(JSON.stringify(this.dealItem))),
      documentsBackup: Object.freeze(
        JSON.parse(JSON.stringify(this.dealItem.documents))
      ),
      readonlyDealItem:
        this.dealItem.is_new == true ? this.orderDetailReadonly : true,
      isDealItemBackupInitialized: false,
      validateConfirm: false,
      errorConfirm: null,
      companiesConsignee: [],
      companiesShipper: [],
      visibleLoadingIndicator: false,
      shipper: {
        company_title: null,
        TIN: null,
        full_name: null,
        phone: null,
        mail: null,
      },
      consignee: {
        company_title: null,
        TIN: null,
        full_name: null,
        phone: null,
        mail: null,
      },
      consigneeContact: {
        contact_name: null,
        contact_email: null,
        contact_phone: null,
      },
      shipperContact: {
        contact_name: null,
        contact_email: null,
        contact_phone: null,
      },
      visibles: {
        companyModalConsignee: false,
        companyModalConsigneeContacts: false,
        companyModalShipper: false,
        companyModalShipperContacts: false,
      },
      dealDocsNotEdited: null,
      optionValues: [
        {
          title: "Выберите способ погрузки",
          id: null,
          disabled: true,
          hidden: true,
        },
        { title: "Не уточнил", id: 3294, disabled: false, hidden: false },
        { title: "Верхний", id: 3295, disabled: false, hidden: false },
        { title: "Боковой", id: 3296, disabled: false, hidden: false },
        { title: "Задний", id: 3297, disabled: false, hidden: false },
        { title: "Аппарели", id: 3298, disabled: false, hidden: false },
        { title: "Верх+Бок", id: 3299, disabled: false, hidden: false },
        { title: "Бок+Зад", id: 3300, disabled: false, hidden: false },
        { title: "Верх+Зад", id: 3301, disabled: false, hidden: false },
        { title: "Верх+Бок+Зад", id: 3302, disabled: false, hidden: false },
        { title: "Гидроборт", id: 3369, disabled: false, hidden: false },
      ],
      preliminaryDateDispatch: [
        {
          title: "Предварительная дата отправки",
          id: null,
          disabled: true,
          hidden: true,
        },
        { title: "Не выбрано", id: null, disabled: false, hidden: true },
        { title: "Сегодня/завтра", id: 5455, disabled: false, hidden: false },
        { title: "Груз готов", id: 5459, disabled: false, hidden: false },
        { title: "Без срока", id: 5458, disabled: false, hidden: false },
        { title: "В течение недели", id: 5456, disabled: false, hidden: false },
        { title: "В течение месяца", id: 5457, disabled: false, hidden: false },
      ],
      loadingMethod: [
        {
          title: "Выберите способ погрузки",
          id: null,
          disabled: true,
          hidden: true,
        },
        { title: "Не выбрано", id: null, disabled: false, hidden: false },
        { title: "Верхний", id: 484, disabled: false, hidden: false },
        { title: "Боковой", id: 486, disabled: false, hidden: false },
        { title: "Задний", id: 488, disabled: false, hidden: false },
        { title: "Аппарели", id: 2012, disabled: false, hidden: false },
        { title: "Верх+Бок", id: 2013, disabled: false, hidden: false },
        { title: "Бок+Зад", id: 2014, disabled: false, hidden: false },
        { title: "Верх+Зад", id: 2015, disabled: false, hidden: false },
        { title: "Верх+Бок+Зад", id: 3292, disabled: false, hidden: false },
        { title: "Гидроборт", id: 3370, disabled: false, hidden: false },
      ],
      unloadingMethod: [
        {
          title: "Выберите способ выгрузки",
          id: null,
          disabled: true,
          hidden: true,
        },
        { title: "Не выбрано", id: null, disabled: false, hidden: false },
        { title: "Верхний", id: 3132, disabled: false, hidden: false },
        { title: "Боковой", id: 3133, disabled: false, hidden: false },
        { title: "Задний", id: 3134, disabled: false, hidden: false },
        { title: "Аппарели", id: 3135, disabled: false, hidden: false },
        { title: "Верх+Бок", id: 3136, disabled: false, hidden: false },
        { title: "Бок+Зад", id: 3137, disabled: false, hidden: false },
        { title: "Верх+Зад", id: 3138, disabled: false, hidden: false },
        { title: "Верх+Бок+Зад", id: 3293, disabled: false, hidden: false },
        { title: "Гидроборт", id: 3307, disabled: false, hidden: false },
      ],
      opportunityNds: [
        {
          title: "Выберите тип отправки",
          id: null,
          disabled: true,
          hidden: true,
        },
        {
          title: "Стоимость Отдельный авто",
          id: 3132,
          disabled: false,
          hidden: false,
        },
        { title: "Стоимость Догруз", id: 3132, disabled: false, hidden: false },
      ],
      opportunityNoNds: [
        {
          title: "Выберите тип отправки",
          id: null,
          disabled: true,
          hidden: true,
        },
        {
          title: "Стоимость Отдельный авто",
          id: 3132,
          disabled: false,
          hidden: false,
        },
        { title: "Стоимость Догруз", id: 3132, disabled: false, hidden: false },
      ],
      debouncedCargoRouteFrom: null,
      debouncedCargoRouteTo: null,
      visibleRouteFromCities: false,
      visibleRouteToCities: false,
      routeFromCities: [],
      routeToCities: [],
      // maxDate: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split("T")[0],
    };
  },
  methods: {
    validateDealItem() {
      let hasEmptyFields = false;

      if (!this.dealItem.shipper.title) {
        hasEmptyFields = true;
        let item = "shipper.title";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "shipper.title";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.shipper.INN) {
        hasEmptyFields = true;
        let item = "shipper.INN";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "shipper.INN";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.shipper.name) {
        hasEmptyFields = true;
        let item = "shipper.name";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "shipper.name";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.shipper.phone) {
        hasEmptyFields = true;
        let item = "shipper.phone";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "shipper.phone";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.shipper.email) {
        hasEmptyFields = true;
        let item = "shipper.email";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "shipper.email";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.loading_date) {
        hasEmptyFields = true;
        let item = "loading_date";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "loading_date";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.consignee.title) {
        hasEmptyFields = true;
        let item = "consignee.title";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "consignee.title";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.consignee.INN) {
        hasEmptyFields = true;
        let item = "consignee.INN";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "consignee.INN";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.consignee.name) {
        hasEmptyFields = true;
        let item = "consignee.name";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "consignee.name";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.consignee.phone) {
        hasEmptyFields = true;
        let item = "consignee.phone";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "consignee.phone";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.consignee.email) {
        hasEmptyFields = true;
        let item = "consignee.email";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "consignee.email";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.preliminary_date_dispatch_id) {
        hasEmptyFields = true;
        let item = "preliminary_date_dispatch_id";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "preliminary_date_dispatch_id";
        this.$refs[item].classList.remove("invalid");
      }
      // if (!this.dealItem.preliminary_loading_method_id) {
      //   hasEmptyFields = true
      //   let item = "preliminary_loading_method_id"
      //   this.$refs[item].classList.add("invalid");
      // } else {
      //   // hasEmptyFields = false
      //   let item = "preliminary_loading_method_id"
      //   this.$refs[item].classList.remove("invalid");
      // }
      if (!this.dealItem.unloading_method_id) {
        hasEmptyFields = true;
        let item = "unloading_method_id";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "unloading_method_id";
        this.$refs[item].classList.remove("invalid");
      }
      if (!this.dealItem.loading_method_id) {
        hasEmptyFields = true;
        let item = "loading_method_id";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "loading_method_id";
        this.$refs[item].classList.remove("invalid");
      }
      // if (!this.dealItem.unloading_date) {
      //   hasEmptyFields = true
      //   let item = "unloading_date"
      //   this.$refs[item].classList.add("invalid");
      // } else {
      //   // hasEmptyFields = false
      //   let item = "unloading_date"
      //   this.$refs[item].classList.remove("invalid");
      // }
      if (!this.dealItem.loading_method_id) {
        hasEmptyFields = true;
        let item = "loading_method_id";
        this.$refs[item].classList.add("invalid");
      } else {
        // hasEmptyFields = false
        let item = "loading_method_id";
        this.$refs[item].classList.remove("invalid");
      }
      if (hasEmptyFields) {
        this.validateConfirm = false; // Если есть незаполненные поля, устанавливаем validateConfirm в false
        this.errorConfirm = "Заполните обязательные поля";
        return false;
      } else {
        this.validateConfirm = true; // Если нет незаполненных полей, устанавливаем validateConfirm в true
        return true;
      }
    },
    openSpollersBody() {
      this.visibleSpollersBody = !this.visibleSpollersBody;
    },
    openConfirmOrder() {
      // e.preventDefault();
      if (this.validateDealItem()) {
        this.visibleConfirmOrder = !this.visibleConfirmOrder;
      } else {
        // console.log("fail");
      }
    },
    openEditBtn(e) {
      e.preventDefault();
      this.visibleEditBtn = !this.visibleEditBtn;
      this.detailOrderTitle = "Редактирование заявки";
      this.readonlyDealItem = false;
    },
    cancelEdit(e) {
      e.preventDefault();
      this.getDetailDeal(null);
      this.visibleEditBtn = !this.visibleEditBtn;
      this.detailOrderTitle = "Детальный просмотр заявки";
      // this.dealItem = this.dealItemBackup;
      this.readonlyDealItem = true;
    },
    async editDeal(e) {
      e.preventDefault();
      this.visibleLoadingIndicator = true;
      for (let i = 0; i < this.dealItem.loading_point.length; i++) {
        if (
          this.dealItem.loading_point[i] === null ||
          this.dealItem.loading_point[i] === ""
        ) {
          this.dealItem.loading_point.splice(i, 1);
        }
      }
      for (let i = 0; i < this.dealItem.unloading_point.length; i++) {
        if (
          this.dealItem.unloading_point[i] === null ||
          this.dealItem.unloading_point[i] === ""
        ) {
          this.dealItem.unloading_point.splice(i, 1);
        }
      }
      if (
        JSON.stringify(this.documentsBackup) ===
        JSON.stringify(this.dealItem.documents)
      ) {
        this.dealDocsNotEdited = "not edited";
      }
      let dealData = {
        // fields: {
        route_from: this.dealItem.route_from ? this.dealItem.route_from : null,
        route_to: this.dealItem.route_to ? this.dealItem.route_to : null,
        cargo_name: this.dealItem.cargo_name ? this.dealItem.cargo_name : null,
        cargo_cost: this.dealItem.cargo_price
          ? this.dealItem.cargo_price
          : null,
        cargo_dimensions: this.dealItem.cargo_dimensions
          ? this.dealItem.cargo_dimensions
          : null,
        cargo_weight: this.dealItem.cargo_weight
          ? this.dealItem.cargo_weight
          : null,
        cargo_volume: this.dealItem.cargo_volume
          ? this.dealItem.cargo_volume
          : null,
        documents: this.dealDocsNotEdited
          ? this.dealDocsNotEdited
          : this.dealItem.documents,
        loading_address: this.dealItem.loading_point
          ? this.dealItem.loading_point
          : null,
        loading_date_time: this.dealItem.loading_date
          ? this.dealItem.loading_date
          : null,
        unloading_address: this.dealItem.unloading_point
          ? this.dealItem.unloading_point
          : null,
        // 'unloading_date_time': this.dealItem.unloading_date ? this.dealItem.unloading_date : null,
        loading_method_id: this.dealItem.loading_method_id
          ? this.dealItem.loading_method_id
          : null,
        unloading_method_id: this.dealItem.unloading_method_id
          ? this.dealItem.unloading_method_id
          : null,
        // 'preliminary_loading_method_id': this.dealItem.preliminary_loading_method_id ? this.dealItem.preliminary_loading_method_id : null,
        preliminary_date_dispatch_id: this.dealItem.preliminary_date_dispatch_id
          ? this.dealItem.preliminary_date_dispatch_id
          : null,
        selected_opportunity_field: this.dealItem.selected_opportunity_field
          ? this.dealItem.selected_opportunity_field
          : null,
        shipper: {
          company_title: this.dealItem.shipper.title
            ? this.dealItem.shipper.title
            : null,
          TIN: this.dealItem.shipper.INN ? this.dealItem.shipper.INN : null,
          full_name: this.dealItem.shipper.name
            ? this.dealItem.shipper.name
            : null,
          phone: this.dealItem.shipper.phone
            ? this.dealItem.shipper.phone
            : null,
          mail: this.dealItem.shipper.email
            ? this.dealItem.shipper.email
            : null,
        },
        consignee: {
          company_title: this.dealItem.consignee.title
            ? this.dealItem.consignee.title
            : null,
          TIN: this.dealItem.consignee.INN ? this.dealItem.consignee.INN : null,
          full_name: this.dealItem.consignee.name
            ? this.dealItem.consignee.name
            : null,
          phone: this.dealItem.consignee.phone
            ? this.dealItem.consignee.phone
            : null,
          mail: this.dealItem.consignee.email
            ? this.dealItem.consignee.email
            : null,
        },
        // },
      };
      let token = localStorage.getItem("token");
      // this.validateDealItem()
      let url = process.env.VUE_APP_URL;
      // if(this.validateConfirm) {
      // console.log('da');
      await axios
        .put(url + "deals/" + this.dealItem.number_deal, dealData, {
          headers: { "Authorization-Token": token },
        })
        .then((response) => {
          // console.log(response);
          // this.visibleEditBtn = !this.visibleEditBtn;
          this.visibleLoadingIndicator = false;
          this.detailOrderTitle = "Детальный просмотр заявки";
          // this.readonlyDealItem = true;
          this.errorConfirm = null;
          this.getDetailDeal(null);
          setTimeout(() => {
            this.isObjectEdit = false;
          }, 2000);
          this.dealDocsNotEdited = null;
          this.documentsBackup = Object.freeze(
            JSON.parse(JSON.stringify(this.dealItem.documents))
          );
          console.log(this.documentsBackup);

          const elements = document.querySelectorAll(".invalid");
          elements.forEach((element) => {
            element.classList.remove("invalid");
          });
        })
        .catch((err) => {
          console.log(err);
          this.visibleLoadingIndicator = false;
          this.dealDocsNotEdited = null;
        });
      // }
    },
    onFileChange(event) {
      let selectedFile = event.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        let fileData = {
          name: selectedFile.name,
          data: fileReader.result.split(",")[1],
        };
        this.dealItem.documents.push(fileData);
      };
    },
    addAdressPole(adress) {
      this.dealItem[adress].push(null);
    },
    changeVisibleCompany(object, value, interval) {
      setTimeout(() => {
        this.visibles[object] = value;
        // console.log(1);
      }, interval);
    },
    autoCompleteCompany(object, name, company) {
      this[object] = company;
      this[name].company_title = company.title;
      this[name].TIN = company.INN;
      // this.consignee.full_name = company.contact_name
      // this.consignee.phone = company.contact_phone
      // this.consignee.mail = company.contact_email
    },
    autoCompleteContact(name, contact) {
      this[name].contact_name = contact.name;
      this[name].contact_email = contact.email;
      this[name].contact_phone = contact.phone;
      // console.log(name);
      // this.consignee.full_name = company.contact_name
      // this.consignee.phone = company.contact_phone
      // this.consignee.mail = company.contact_email
    },
    deleteDocument(index) {
      this.dealItem.documents.splice(index, 1);
    },
    async cargoRouteFrom(val) {
      console.log(val);
      if (val !== null && val !== undefined && val !== "") {
        this.debouncedCargoRouteFrom(val);
      }
    },
    async cargoRouteTo(val) {
      console.log(val);
      if (val !== null && val !== undefined && val !== "") {
        this.debouncedCargoRouteTo(val);
      }
    },
    async cargo_route_from_api_call(val) {
      try {
        const response = await getCities(val);
        console.log(response);
        this.routeFromCities = response.data;
      } catch (error) {
        console.error("Error fetching cities for route from:", error);
      }
    },
    async cargo_route_to_api_call(val) {
      try {
        const response = await getCities(val);
        console.log(response);
        this.routeToCities = response.data;
      } catch (error) {
        console.error("Error fetching cities for route to:", error);
      }
    },
    changeVisibleRouteFromCities(interval) {
      setTimeout(() => {
        this.visibleRouteFromCities = !this.visibleRouteFromCities;
        // console.log(1);
      }, interval);
    },
    changeVisibleRouteToCities(interval) {
      setTimeout(() => {
        this.visibleRouteToCities = !this.visibleRouteToCities;
        // console.log(1);
      }, interval);
    },
  },
  computed: {
    maxDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      const currentDateTime = `${
        year + 1
      }-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return currentDateTime;
    },
    parseDealOpportunity() {
      let num = this.dealItem.opportunity;
      num = num.split(".")[0];
      num = num.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return num;
    },
  },
  mounted() {
    getDetailCompanies(localStorage.getItem("token")).then((response) => {
      this.companiesConsignee = response.companies.consignee;
      this.companiesShipper = response.companies.shipper;
    });
  },
  watch: {
    // dealItem() {
    //   console.log('ee');
    //   if (JSON.stringify(this.dealItem) == JSON.stringify(this.dealItemBackup)) {
    //     console.log('trues');
    //   } else {
    //     console.log('faels');
    //   }
    // },
    dealItem: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.dealItemBackup)) {
          this.isObjectEdit = true;
        }
      },
      deep: true,
    },
  },
  created() {
    this.debouncedCargoRouteFrom = debounce(
      this.cargo_route_from_api_call,
      1000
    );

    this.debouncedCargoRouteTo = debounce(this.cargo_route_to_api_call, 1000);
  },
};
</script>

<style scoped>
input[type='radio'] {
  accent-color: #ba181b;
}
.popup.orderpopup {
  z-index: 1150;
  visibility: visible;
  overflow: auto;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.403);
}
.line-orderpopup__select:disabled {
  opacity: 1;
}
.line-orderpopup__input {
  outline: none;
}
.orderpopup__content {
  transform: scale(1);
  visibility: visible;
}
.invalid {
  border: 1px solid red;
}
.body-orderpopup__form.form-orderpopup {
  overflow-y: scroll;
  max-height: calc(100vh - 162px);
  padding-right: 20px;
}
.line-orderpopup__select:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.line-orderpopup__select:disabled::-ms-expand {
  display: none;
}
.body-orderpopup__form.form-orderpopup::-webkit-scrollbar {
  width: 7px;
  background-color: #f9f9fd;
}
.body-orderpopup__form.form-orderpopup::-webkit-scrollbar-thumb {
  background-color: #ba181b;
  border-radius: 5px;
}
.line-orderpopup__subtitle .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.line-orderpopup__subtitle:hover .tooltiptext {
  visibility: visible;
}
.orderpopup__wrapper .confirm-deal {
  background-color: #e7ede3;
}
.price-orderpopup__line-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-orderpopup__line-left input {
  margin-right: 10px;
  outline: none;
}
/* .line-orderpopup__input_date{
  padding-left: clamp( 0.4375rem , 0.2983695652rem  +  0.6956521739vw , 0.9375rem ) !important;
} */
</style>
