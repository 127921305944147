<template>
    <div class="body-chat__message message-chat body-chat__message_incoming">
        <div class="message-chat__text">Привет</div>
        <div class="message-chat__info">
            <div class="message-chat__name">Goroxxx</div>
            <div class="message-chat__time">10:00</div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>